(function() {
    'use strict';

    angular
        .module('xposApp')
        .factory('SettingAdminService', SettingAdminService);

    SettingAdminService.$inject = ['$resource', '$localStorage', '$http'];

    function SettingAdminService ($resource,  $localStorage, $http) {
        var service = {
            getSeting : getSeting,
            getOptionSetting : getOptionSetting,
            updateSetting : updateSetting,
            getHardisks : getHardisks
        }
        var API_URL = $localStorage.API_URL;
        var setting = [];

        function getHardisks () {
            return $http.get(API_URL+'api/command/getHardisks').then(getHardiskComplete);

            function getHardiskComplete (response) {
                var properties = [];
                angular.forEach(response.data, function (data) {
                    properties.push(data);
                });
                return properties;
            }
        }
        function getSeting(){
            var settingTmp =  $localStorage.setting;
            if (angular.isUndefined(setting)) settingTmp =  setting;
            return setting;
        };

        function getOptionSetting(key){
            return setting[key];
        };

        function updateSetting(setting){
            console.log(setting);
           $localStorage.setting = setting;
        } 

        return service;
    }
})();
