(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('DataBackupController', DataBackupController);

    DataBackupController.$inject = ['$state','$uibModal','DataBackup', '$http', '$q', '$localStorage'];

    function DataBackupController($state, $uibModal, DataBackup, $http, $q, $localStorage) {

        var vm = this;
        vm.apiUrl = $localStorage.API_URL;
        var API_URL = $localStorage.API_URL;

        vm.dataBackups = [];

        loadAll();

        function loadAll() {
            DataBackup.query(function(result) {
                vm.dataBackups = result;
                vm.searchQuery = null;
            });
        }

         var makeRestCall   = function (url) {
            return $http.get(API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

        

        vm.recovery = recovery;
        function recovery(fileName){
           var txt =  document.getElementById("confirmRecovery").innerHTML;
            var r = confirm(txt);
            if (r == true) {
                makeRestCall('api/data-backup/recovery?fileName='+fileName).then(function(data){
                        if(data.data != null && data.data.value == "OK"){
                            txt =  document.getElementById("recoverySuccess").innerHTML;
                            alert(txt);                                          
                        }else{
                            txt =  document.getElementById("errorRecovery").innerHTML;
                            alert(txt);  
                        }

                    },function(error){
                });
            } else {
               
            }
        }

        vm.remove = remove;
        function remove(fileName){
            var txt =  document.getElementById("confirmDelete").innerHTML;
            var r = confirm(txt);
            if (r == true) {
                makeRestCall('api/data-backup/remove?fileName='+fileName).then(function(data){
                        if(data.data != null && data.data.value == "OK"){
                               loadAll();                                               
                        }else{
                            txt =  document.getElementById("errorRemove").innerHTML;
                            alert(txt);   
                        }

                    },function(error){
                });
            } else {
               
            }
           
        }

        vm.backupManually = backupManually;
        function backupManually(){
            makeRestCall('api/backup-manually').then(function(data){
                        alert("OK");   
                        loadAll(); 
                    },function(error){
                        alert("ERROR");   
            });
        }

        vm.resetDB = resetDB;
        function resetDB(){
            makeRestCall('api/data-backup/reset').then(function(data){
                        alert("OK");   
                        loadAll(); 
                    },function(error){
                        alert("ERROR");   
            });
        }

        vm.clean = clean;
        function clean(){
            makeRestCall('api/delete-all-orders').then(function(data){
                        alert("OK");   
                    },function(error){
                        alert("ERROR");   
            });
        }

        vm.openXemHoaDon = function(){
            $uibModal.open({
                  templateUrl: 'app/admin/data-backup/modal-verify.html',
                  controller: function ($scope, $uibModalInstance) {
                    $scope.verified = false;
                    
                    $scope.cancel = function () {
                      $uibModalInstance.dismiss('cancel');
                    };
                    
                    $scope.tryPassword = function(){
                        //console.log($scope.passVerify);
                         makeRestCall('api/setting?type=PASS_2').then(function(data){
                          if(data != null){
                                var pass = data.data.setting;
                                if($scope.passVerify == pass){
                                    makeRestCall('api/bills/xem-bills').then(function(data){
                                        $state.go('bill');
                                        $scope.cancel();
                                    },function(error){
                                        $scope.cancel();
                                    });
                                    
                                }else {
                                    $scope.cancel();
                                }
                          }
                               
                            },function(error){
                        });
                         /*
                        
                        */
                    }
                }
            })
        }
    }
})();
