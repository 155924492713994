(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('BillController', BillController);

    BillController.$inject = ['Store','Auth','Principal','$timeout','$scope','$rootScope','$state', 'Bill', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','PosSetting','$http', '$q', '$localStorage','DateUtils','User'];

    function BillController(Store,Auth, Principal, $timeout, $scope, $rootScope, $state, Bill, ParseLinks, AlertService, paginationConstants, pagingParams, PosSetting,$http, $q, $localStorage,DateUtils, User) {

    	 var vm = this;
         vm.apiUrl = $localStorage.API_URL;
         vm.$state = $state;

         vm.dateformat = 'dd-MM-yyyy';
       
         vm.printBill = printBill;
         vm.search = {};
         vm.loadAll = loadAll;
        

        vm.dateSearch = new Date();
        vm.dateSearch.setHours(0);
        vm.dateSearch.setMinutes(0);
        vm.dateSearch.setSeconds(0);

        vm.dateTo = new Date();
         vm.dateTo.setHours(0);
        vm.dateTo.setMinutes(0);
        vm.dateTo.setSeconds(0);

        function makePutCall(url,data) {
            return $http.put(vm.apiUrl+url,data)
                .then(function (response) {
                  return response;
                }, function (response) {
                    return $q.reject(response.data);
                });
        };

        Principal.identity().then(function(account) {
            vm.account = account;
            if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
            }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
             }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
             }

            vm.filterStore = vm.account.store;
            loadAll();
            loadAllStore();
           
        });

         $scope.open1 = function() {
            $scope.popup1.opened = true;
          };

        $scope.popup1 = {
            opened: false
          };

           $scope.open2 = function() {
            $scope.popup2.opened = true;
          };

        $scope.popup2 = {
            opened: false
          };



        var makeRestCall   = function (url) {

            return $http.get(vm.apiUrl +url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };
       
        
      

        function printBill(billId){
            
           $state.go('bill-print-preview',{id:billId});
           
        }

         function loadAll () {
            var searchFrom = new Date(vm.dateSearch);
            var searchTo = new Date(vm.dateTo);

         	var dataQuery = {
                     searchFrom: searchFrom,
                     searchTo: searchTo,
                     sortBy: sort()
                 };
                 if(vm.filterStore.id != 1){
                    dataQuery.storeId =  vm.filterStore.id;
                 }
                
                 // get Bills
                 Bill.query(dataQuery, onSuccessGetBill, onErrorGetBill);
                 function sort() {
                     var result = ['id:desc'];
                     return result;
                 }
                 function onSuccessGetBill(data, headers) {
                     vm.bills = data.content;
                     //console.log(vm.bills);
                     // for computing report
                     computeReport(vm.bills);
                     


                 }
                 function onErrorGetBill(error) {
                     AlertService.error(error);
                 }
         }

        function computeReport(bills){
            vm.time = new Date();
            vm.billCount = bills.length;
            vm.totalMoney = 0;
            vm.totalInputMoney = 0;
            vm.totalMoneyDelete = 0;
            vm.totalMoneyProduct = 0;
            vm.totalInputMoneyProduct = 0;
            vm.totalProduct = 0;

            vm.products = [];
            for(var i=0;i< bills.length; i++){
                if(bills[i].status == 'FINISH'){
                   vm.totalMoney += bills[i].totalValue;       
                }else  if(bills[i].status == 'DELETE'){
                    vm.totalMoneyDelete += bills[i].totalValue;
                }

                if(bills[i].status == 'FINISH'){
                    var listProducts = bills[i].listProducts;
                    for(var j=0;j<listProducts.length;j++){
                        var exist = false;
                        for(var t=0;t<vm.products.length;t++){
                            if(vm.products[t].productId == listProducts[j].productId){
                                exist = true;
                                vm.products[t].count += listProducts[j].quantity;
                                vm.products[t].totalMoney += listProducts[j].totalMoney;

                                vm.products[t].inputMoney += listProducts[j].inputMoney;
                            }
                        }
                        if(exist == false){
                            var p = {};
                            p.productId = listProducts[j].productId;
                            p.name = listProducts[j].name;
                            p.count = listProducts[j].quantity;
                            p.inputMoney = listProducts[j].inputMoney;
                            p.totalMoney = listProducts[j].totalMoney;
                            vm.products.push(p);
                        }
                    }
                    
                }
            }
            for(var i=0;i<vm.products.length;i++){
                vm.totalMoneyProduct += vm.products[i].totalMoney;
                vm.totalInputMoneyProduct += vm.products[i].inputMoney;
                vm.totalProduct += vm.products[i].count;
            }
            //console.log(vm.totalMoney);
         }
         vm.loadAllStore = loadAllStore;
         function loadAllStore(){
                 var dataQuery = {
                 };
                 // get Bills
                 Store.query(dataQuery, onSuccess, onError);
                 
                 function onSuccess(data, headers) {
                     vm.stores = data.content;
                    //console.log(vm.stores);
                 }
                 function onError(error) {
                     AlertService.error(error);
                 }
         }
   
        vm.resetList = resetList;
        function resetList(){
            loadAll();
        }

        vm.gotoDetail = function(id){
            $state.go('bill-detail',{id:id});
        }
         
         vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }

        vm.deleteBill = function(bill){
            if(bill.status != 'DELETE'){
                var txt =  "Bạn thực sự muốn đơn hàng này?";
                var r = confirm(txt);
                if (r == true) {
                    var updatedProductDTO = {};
                    updatedProductDTO.billId = bill.id;
                    updatedProductDTO.mode = "DELETE-ORDER";
                     makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                        alert("Xóa đơn hàng "+updatedProductDTO.billCode+" thành công.");
                        loadAll();
                    },function(error){
                    });
                 }
            }
            
        }

    }
})();
