(function() {
	'use strict';

	angular.module('xposApp').controller('OrderPosDeleteOrderController',
			OrderPosDeleteOrderController);

	OrderPosDeleteOrderController.$inject = [ '$timeout', '$rootScope',
			'$stateParams', '$uibModalInstance', 'AlertService', 'OrderPos', 'Principal','Bill','Product','$http','$localStorage'];

	function OrderPosDeleteOrderController($timeout, $rootScope, $stateParams,
			$uibModalInstance, AlertService, OrderPos, Principal,Bill, Product, $http,$localStorage) {
		var vm = this;
		vm.orderId = $stateParams.order;
		
		vm.clear = clear;
		vm.deleteOrder = deleteOrder;
		
		getcurrentOrder(vm.orderId);

		var makeRestCall   = function (url) {
            return $http.get($localStorage.API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

		makeRestCall('api/setting?type=STOCK_MANAGEMENT').then(function(data){
                     if(data.data != null){
                        vm.stockManagement = data.data.setting;
                    }
                  },function(error){
          });


		function getcurrentOrder(orderID){
        	OrderPos.get({ id: orderID  }, onSuccessLoadCurrentOrder, onErrorLoadCurrentOrder);
            function onSuccessLoadCurrentOrder(data, headers) {
                vm.currentOrder = data;
            }
            function onErrorLoadCurrentOrder(error) {
                AlertService.error(error.data.message);
            }
        }
		
		function deleteOrder(){
			if( vm.orderId != null){
				if(vm.currentOrder.listProducts.length <1){
					// neu khong co san pham thi xoa
					OrderPos.delete({ id: vm.orderId  }, onDeleteSuccess);
		        	function onDeleteSuccess(data){
		        		vm.currentOrder = null;
	 					$rootScope.$broadcast('newOrder', vm.currentOrder);
	                    //$uibModalInstance.close(true);
	                    $uibModalInstance.dismiss('cancel');
		        	}
				}else{
					//// neu order co san pham thi switch sang stornos (mat hang xoa) roi luu Bill
					//switch stornos
					for(var i=0;i<vm.currentOrder.listProducts.length;i++){
						vm.currentOrder.listProducts[i].stornors = vm.currentOrder.listProducts[i].stornors+ vm.currentOrder.listProducts[i].quantity;
						
						// update quantity of product in warehouse
						if(vm.stockManagement == '1'){
							vm.currentOrder.listProducts[i].product.quantity = vm.currentOrder.listProducts[i].product.quantity + vm.currentOrder.listProducts[i].quantity;
							Product.update(vm.currentOrder.listProducts[i].product, onSaveProductSuccess, onSaveProductError);
				             function onSaveProductSuccess(data, headers){
				                   if(data != null){
				                    
				                   }
				            }
				            function onSaveProductError(){
				            }
						}
						
			            

						//update quantity of current orderedProduct
						vm.currentOrder.listProducts[i].quantity = 0;
					}
					//save bill
					vm.currentOrder.orderStatus = "FINISH";
		            vm.currentOrder.endAt = new Date();
		            vm.currentOrder.cash = 0;
		    		vm.currentOrder.quantity = 0;
		    		vm.currentOrder.totalMoney = 0;

		            OrderPos.update(vm.currentOrder, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
		            function onSaveCurrentOrderSuccess (data) {
		                insertNewBill(data);
		                vm.currentOrder = null;
		            }

		            function onSaveCurrentOrderError () {
		                vm.currentOrder = null;
		                alert("Error Delete")
		            }
				}
				
			}else{
				$uibModalInstance.dismiss('cancel');
			}
			
		}

		function insertNewBill(order){
            // insert new bill to db
            var bill = {}
            bill.id = null;
            bill.code = null;
            bill.tablePos = order.tablePos;
            bill.waiter = order.waiter;
            bill.startedAt = order.startedAt;
            bill.endAt = order.endAt;
            bill.orderId = order.id;
            bill.numberGuest = order.numberGuest;
            bill.customerDTO = order.customerDTO;
            bill.listProducts = order.listProducts;
            bill.totalValue = order.totalValue;
            bill.discountValue = order.totalDiscount;
            bill.totalVAT = order.totalVAT;
            bill.subTotal = order.subTotal;
            bill.chargeMoney = 0;
            bill.cash = order.totalValue;
            bill.paymentMethod = 'CASH';
            bill.splitOrder = 'NO';
            
            
            //console.log("Request Create New Bill")
            //console.log(JSON.stringify(bill, null, "    ") )
            Bill.save(bill, onSaveBillSuccess, onSaveBillError);
            
            function onSaveBillSuccess(data){
                vm.currentOrder = null;
	 			$rootScope.$broadcast('newOrder', vm.currentOrder);
	            //$uibModalInstance.close(true);
	            $uibModalInstance.dismiss('cancel');
            }
            function onSaveBillError(){
                AlertService.error("Insert Bill Error");
            }
        }
		

		function clear() {
			
			$uibModalInstance.dismiss('cancel');
		}



	}
})();
