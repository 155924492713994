(function() {
    'use strict';

    angular
        .module('xposApp')
        .factory('keyboardHandler', keyboardHandler);

    keyboardHandler.$inject = ['$rootScope', '$state', '$localStorage'];

    function keyboardHandler($rootScope, $state, $localStorage ) {
        return {
            initialize: initialize
        };

        function initialize() {
            // if (angular.isUndefined($localStorage.API_URL_VAL)){
               // $localStorage.API_URL_VAL = "pos.myphamshuface.vn";
               // $localStorage.API_URL = "https://pos.myphamshuface.vn/";
               
                //$localStorage.API_URL_VAL = "127.0.0.1:9090";
                //$localStorage.API_URL = "http://127.0.0.1:9090/";

               $localStorage.API_URL_VAL = "thuthanhminh.com";
               $localStorage.API_URL = "https://thuthanhminh.com/";

                
            // } 
            if (angular.isUndefined($localStorage.setting)){
                $localStorage.setting = {};
                if (angular.isUndefined($localStorage.virtualkeyboard))
                $localStorage.setting.virtualkeyboard = false;
            }
            };

        }
})();
