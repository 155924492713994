(function() {
    'use strict';

    angular
        .module('xposApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pos-setting', {
            parent: 'entity',
            url: '/pos-setting',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.posSetting.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pos-setting/pos-settings.html',
                    controller: 'PosSettingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pos-setting-detail', {
            parent: 'pos-setting',
            url: '/pos-setting/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.posSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pos-setting/pos-setting-detail.html',
                    controller: 'PosSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PosSetting', function($stateParams, PosSetting) {
                    return PosSetting.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'pos-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('pos-setting-detail.edit', {
            parent: 'pos-setting-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pos-setting/pos-setting-dialog.html',
                    controller: 'PosSettingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PosSetting', function(PosSetting) {
                            return PosSetting.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pos-setting.new', {
            parent: 'pos-setting',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pos-setting/pos-setting-dialog.html',
                    controller: 'PosSettingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                pos: null,
                                setting: null,
                                type: null,
                                lastUpdate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('pos-setting', null, { reload: 'pos-setting' });
                }, function() {
                    $state.go('pos-setting');
                });
            }]
        })
        .state('pos-setting.edit', {
            parent: 'pos-setting',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pos-setting/pos-setting-dialog.html',
                    controller: 'PosSettingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PosSetting', function(PosSetting) {
                            return PosSetting.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pos-setting', null, { reload: 'pos-setting' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pos-setting.delete', {
            parent: 'pos-setting',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pos-setting/pos-setting-delete-dialog.html',
                    controller: 'PosSettingDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PosSetting', function(PosSetting) {
                            return PosSetting.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pos-setting', null, { reload: 'pos-setting' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
