(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('NavigateController', NavigateController);

    NavigateController.$inject = ['Auth','$scope', 'Principal', '$state','$localStorage'];

    function NavigateController (Auth, $scope, Principal,$state,  $localStorage) {
        var vm = this;
        
        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                // $rootScope.flag = vm.account.langKey;
                vm.isAuthenticated = Principal.isAuthenticated;
                 if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
                 }

                 //console.log(vm.currentRole);
            });
        }

        vm.switchTo = function(state){
            $state.go(state);
        }

        vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }
        
    }
})();
