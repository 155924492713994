(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('BillDetailController', BillDetailController);

    BillDetailController.$inject = ['$timeout','$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Bill', 'Customer'];

    function BillDetailController($timeout, $scope, $rootScope, $stateParams, previousState, entity, Bill, Customer) {
        var vm = this;

        vm.bill = entity;
        //console.log(vm.bill);
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('xposApp:billUpdate', function(event, result) {
            vm.bill = result;

        });
        $scope.$on('$destroy', unsubscribe);
/*
         vm.reFocus =reFocus;
        function reFocus(){
            angular.element('#input_focus').focus();
        }
        $timeout(function (){
            vm.reFocus();
        });
        */
    }
})();
