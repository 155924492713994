(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('StockinDetailController', StockinDetailController);

    StockinDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Stockin', 'StockinCategory', 'HappyHousePrice', 'StockinGroup', 'Pos'];

    function StockinDetailController($scope, $rootScope, $stateParams, previousState, entity, Stockin, StockinCategory, HappyHousePrice, StockinGroup, Pos) {
        var vm = this;

        vm.stockin = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('xposApp:stockinUpdate', function(event, result) {
            vm.stockin = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
