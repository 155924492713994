(function() {
	'use strict';

	angular.module('xposApp').controller('OrderPosQuickCommentController',
			OrderPosQuickCommentController);

	OrderPosQuickCommentController.$inject = [ '$timeout', '$rootScope', '$scope','PosSetting', '$http', '$q','$localStorage',
			'$stateParams', '$uibModalInstance', 'AlertService', 'entity','Customer' ,'JhiLanguageService'];

	function OrderPosQuickCommentController($timeout, $rootScope,$scope,PosSetting, $http, $q,$localStorage,
			$stateParams, $uibModalInstance, AlertService, entity, Customer, JhiLanguageService) {
		var vm = this;
		 vm.apiUrl = $localStorage.API_URL;
        var API_URL = $localStorage.API_URL;
		vm.currentOrder = entity;
		vm.clear = clear;
		vm.loadAllComment = loadAllComment;
		vm.addComment = addComment;
    vm.selectCommentTemplate = selectCommentTemplate;

		var makeRestCall   = function (url) {
            return $http.get($localStorage.API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

		for (var i = 0; i < vm.currentOrder.listProducts.length; i++) { 
                    if(vm.currentOrder.listProducts[i].product.productName == $stateParams.productName){
                        vm.comment = vm.currentOrder.listProducts[i].comment;
                    }
         }

		initKeyboard();
		loadAllComment();

		$scope.$on('languageChange', function(data) {
            initKeyboard();
        });
		function initKeyboard(){
            
            vm.t04Config = {layout: JhiLanguageService.getKeyboard(), kt: 'US International', showKbSelect: true,
             numberPad: true, showVersion: true, usePreview: false, autoAccept : true, reposition : true,
             position : {
                  // null (attach to input/textarea) or a jQuery object (attach elsewhere)
                  of : $(window),
                  my : 'center bottom',
                  at : 'center bottom',
                  // at2 is used when "usePreview" is false (centers keyboard at the bottom
                  // of the input/textarea)
                  at2: 'center bottom',
                  collision: 'flipfit flipfit'
                },
                visible: function(e, keyboard, el) {
                  keyboard.$preview[0].select();
                }
         }
        }

       

		// ***
		// Load all Comment resource
		function loadAllComment() {
			makeRestCall('api/setting?type=COMMENTS_TEMPLATE').then(function(data){
                      var commentsString = data.data.setting;
                      vm.comments = commentsString.split(";");

                  },function(error){
          });
		}

		function addComment(comment){
			vm.comment = comment;
			var itemName = $stateParams.productName;
			for (var i = 0; i < vm.currentOrder.listProducts.length; i++) { 
                    if(vm.currentOrder.listProducts[i].product.productName == itemName){
                        vm.currentOrder.listProducts[i].comment = vm.comment;
                    }
            }

			$rootScope.$broadcast('updateCurrentOrder', vm.currentOrder);
            $uibModalInstance.dismiss('cancel');
		}
		
    function selectCommentTemplate(comment){
      if(vm.comment && vm.comment.length >0){
        vm.comment = vm.comment +"; "+comment;
      }else{
        vm.comment = comment;
      }
    }
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

	}
})();
