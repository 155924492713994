(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('PaymentDialogController2', PaymentDialogController2);

    PaymentDialogController2.$inject = ['Auth','$state','$timeout', '$rootScope', '$stateParams', '$uibModalInstance','AlertService', 
    'entity', 'OrderPos','Bill','PosSetting', '$http', '$q','$localStorage'];

    function PaymentDialogController2 (Auth, $state, $timeout, $rootScope, $stateParams, $uibModalInstance, AlertService, entity,
     OrderPos, Bill,PosSetting, $http, $q, $localStorage) {
    	var vm = this;
        vm.apiUrl = $localStorage.API_URL;
        var API_URL = $localStorage.API_URL;
        vm.currentOrder = entity;
        vm.clear = clear;
        vm.payment = payment;

        vm.cash = 0;
        vm.card = 0;
        vm.voucher = 0;
        vm.check = 0;
        vm.summe = 0;
        vm.totalValue = Number(vm.currentOrder.totalValue);
        vm.charge = vm.totalValue * (-1);
        vm.currentMethod = 'cash';
       
        //print region
        // *****
        var makeRestCall   = function (url) {
            return $http.get(API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };
        makeRestCall('api/setting?type=PRINTORDER').then(function(data){
                vm.selectPrintOrder = data.data.setting;
            },function(error){
        });
        makeRestCall('api/setting?type=PRINTA4').then(function(data){
                vm.selectPrintA4 = data.data.setting;
            },function(error){
        });
         makeRestCall('api/setting?type=SINGLE_KITCHEN').then(function(data){
            if(data.data != null){
                vm.singleItemKitchenMode = data.data.setting;
                }
            },function(error){
          });

        var makeRestCall   = function (url) {
            return $http.get(API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };
        function printBill(mode, billId){
            if(mode == 'BILL'){
                makeRestCall('api/print/invoce?objectid='+billId+'&mode=bill&printerName='+vm.selectPrintOrder).then(function(data){
                },function(error){

                });
            }
            if(mode == 'EVIDENCE'){
                makeRestCall('api/print/invoce?objectid='+billId+'&mode=evidence&printerName='+vm.selectPrintOrder).then(function(data){
                },function(error){

                });
            }
            if(mode == 'BILLA4'){
                makeRestCall('api/print/a4?objectid='+billId+'&mode=billa4&printerName='+vm.selectPrintA4).then(function(data){
                },function(error){

                });
            }

        }
        // payment region
        // ***
        vm.selectPaymentMethod = function(method){
            vm.currentMethod = method;
        }
        vm.typeNumber = function(number){
            var method = vm.currentMethod;
            if(method == 'cash'){
                if(number == 'Full'){
                    vm.cash = 0;
                    vm.card = 0;
                    vm.voucher = 0;
                    vm.check = 0;
                    vm.cash = vm.totalValue;
                }else
                if(number == 'CE'){
                    vm.cash = 0;
                }else
                if(vm.cash == 0){
                    vm.cash = Number(number);
                }else{
                    vm.cash = String(vm.cash) + String(number);
                }
            }else if(method == 'card'){
                if(number == 'Full'){
                    vm.cash = 0;
                    vm.card = 0;
                    vm.voucher = 0;
                    vm.check = 0;
                    vm.card = vm.totalValue;
                }else
                if(number == 'CE'){
                    vm.card = 0;
                }else
                if(vm.card == 0){
                    vm.card = Number(number);
                }else{
                    vm.card = String(vm.card) + String(number);
                }
            }else if(method == 'voucher'){
                 if(number == 'Full'){
                    vm.cash = 0;
                    vm.card = 0;
                    vm.voucher = 0;
                    vm.check = 0;
                    vm.voucher = vm.totalValue;
                }else
                if(number == 'CE'){
                    vm.voucher = 0;
                }else
                if(vm.voucher == 0){
                    vm.voucher = Number(number);
                }else{
                    vm.voucher = String(vm.voucher) + String(number);
                }
            }else if(method == 'check'){
                if(number == 'Full'){
                    vm.cash = 0;
                    vm.card = 0;
                    vm.voucher = 0;
                    vm.check = 0;
                    vm.check = vm.totalValue;
                }else
                if(number == 'CE'){
                    vm.check = 0;
                }else
                 if(vm.check == 0){
                    vm.check = Number(number);
                }else{
                    vm.check = String(vm.check) + String(number);
                }
            }
             vm.cash =  String(vm.cash).replace(",", ".");
            vm.card =  String(vm.card).replace(",", ".");
            vm.voucher =  String(vm.voucher).replace(",", ".");
            vm.check =  String(vm.check).replace(",", ".");

            vm.summe = Number(vm.cash) + Number(vm.card) + Number(vm.check) + Number(vm.voucher);
            vm.summe = Math.round(vm.summe* 100.0) / 100.0;
            vm.charge =  Number(vm.summe) - Number(vm.totalValue);
            vm.charge = Math.round(vm.charge * 100.0) / 100.0;
        }

        vm.typeMoneyIcon = function(number){
            if(vm.currentMethod == 'cash'){
                if(vm.cash == 0){
                    vm.cash = Number(number);
                }
                else{
                    vm.cash = Number(vm.cash) + Number(number);
                    vm.cash = Math.round(vm.cash * 100.0) / 100.0;
                }
            }else if(vm.currentMethod == 'card'){
                if(vm.card == 0){
                    vm.card = Number(number);
                }else{
                    vm.card = Number(vm.card) + Number(number);
                    vm.card = Math.round(vm.card * 100.0) / 100.0;
                }
            }else if(vm.currentMethod == 'voucher'){
                if(vm.voucher == 0){
                    vm.voucher = Number(number);
                }else{
                    vm.voucher = Number(vm.voucher) + Number(number);
                    vm.voucher = Math.round(vm.voucher * 100.0) / 100.0;
                }
            }else if(vm.currentMethod == 'check'){
                if(vm.check == 0){
                    vm.check = Number(number);
                }else{
                    vm.check = Number(vm.check) + Number(number);
                    vm.check = Math.round(vm.check * 100.0) / 100.0;
                }
            }
           

            vm.summe = Number(vm.cash) + Number(vm.card) + Number(vm.check) + Number(vm.voucher);
            vm.summe = Math.round(vm.summe* 100.0) / 100.0;
            vm.charge =  Number(vm.summe) - Number(vm.totalValue);
            vm.charge = Math.round(vm.charge * 100.0) / 100.0;
        }
        // ***
        // Payment
        function payment(mode){
        	
        	if(Number(vm.charge) >= 0 ){
                vm.currentOrder.requireFinish = '0';
                vm.currentOrder.cash = Number(vm.cash);
                vm.currentOrder.card = Number(vm.card);
                vm.currentOrder.voucher = Number(vm.voucher);
                vm.currentOrder.checkMoney = Number(vm.check);
                vm.currentOrder.chargeMoney = Number(vm.charge);
              
              if(vm.currentOrder.card > 0 || vm.currentOrder.voucher > 0 || vm.currentOrder.checkMoney >0){
                vm.currentOrder.requireFinish = '1';
              }
              if(mode == 'BILL' || mode == 'BILLA4' || mode=='EVIDENCE'){
                    vm.currentOrder.requireFinish = '1';
              }

             if(mode == 'WAIT'){
                vm.currentOrder.endAt = new Date();
                vm.currentOrder.orderStatus = 'WAIT';
             }
                // update currentOrder to db

               // console.log("Request Update Order (Khi thanh toan)")
               // console.log(JSON.stringify(vm.currentOrder, null, "    ") )
               OrderPos.get({ id: vm.currentOrder.id  }, onSuccessLoadCurrentOrder, onErrorLoadCurrentOrder);
                function onSuccessLoadCurrentOrder(data, headers) {
                    var dbOrder = shallowCopy(data);
                    if(dbOrder.totalValue != vm.currentOrder.totalValue){
                        alert("Warnung! Jemand anders hat diese Reihenfolge geändert");
                         Auth.logout();
                        $state.go('home');
                        $uibModalInstance.dismiss('cancel');
                    }else{
                        OrderPos.update(vm.currentOrder, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                        function onSaveCurrentOrderSuccess (data) {
                            //console.log(data);
                            
                            $rootScope.$broadcast('lastChargeMoney', vm.currentOrder.chargeMoney);
                            printKitchen(data.id);
                            if(mode != 'WAIT'){
                               insertNewBill(data,mode);
                             }
                            
                            vm.currentOrder = null;
                            $localStorage["currentOrder.id"] = null;
                            $rootScope.$broadcast('updateCurrentOrder', vm.currentOrder);

                            $uibModalInstance.dismiss('cancel');

                        }

                        function onSaveCurrentOrderError () {
                            alert("Diese Bestellung kann nicht aktualisiert werden!");
                            $uibModalInstance.dismiss('cancel');
                            //$state.go('make-order');
                        }
                    }

                }
                function onErrorLoadCurrentOrder(error) {
                    AlertService.error(error);
                }

                
           
            }

          
        }
        $timeout(function (){
            //angular.element('.in-money>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.orderPos.id !== null) {
                OrderPos.update(vm.orderPos, onSaveSuccess, onSaveError);
            } else {
                OrderPos.save(vm.orderPos, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('xposApp:orderPosUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function insertNewBill(order, mode){
        	// insert new bill to db
        	var bill = {};
        	bill.id = null;
        	bill.code = null;
        	bill.tablePos = order.tablePos;
        	bill.waiter = order.waiter;
        	bill.startedAt = order.startedAt;
        	bill.endAt = new Date();
        	bill.orderId = order.id;
        	bill.numberGuest = order.numberGuest;
        	bill.customerDTO = order.customerDTO;
        	bill.listProducts = order.listProducts;
        	bill.totalValue = order.totalValue;
        	bill.discountValue = order.totalDiscount;
        	bill.totalVAT = order.totalVAT;
        	bill.subTotal = order.subTotal;


        	bill.cash = order.cash;
            bill.card = order.card;
            bill.voucher = order.voucher;
            bill.checkMoney = order.checkMoney;
            bill.chargeMoney = order.chargeMoney;
         

        	bill.splitOrder = order.splitOrder;

            if(mode == 'BILL' || mode == 'BILLA4' || mode=='EVIDENCE'){
                bill.requireFinish = true;
                bill.print = '1';
            }else{
                 bill.print = '0';
            }
           // console.log("Request Create New Bill")
            //console.log(JSON.stringify(bill, null, "    ") )
        	Bill.save(bill, onSaveBillSuccess, onSaveBillError);

        	function onSaveBillSuccess(data){
                // 1. finish order
                order.orderStatus = "FINISH";
                order.endAt = new Date();
                OrderPos.update(order, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                function onSaveCurrentOrderSuccess (data) {
                }

                function onSaveCurrentOrderError (error) {
                    alert(error);
                }
                // 2. print bill
        		if(mode == 'BILL' || mode == 'BILLA4' || mode=='EVIDENCE'){

        			printBill(mode, data.id);
        		}

        	}
        	function onSaveBillError(){
        		AlertService.error("Insert Bill Error");
        	}
        }
        // ******
        // Print kitchen when finish
        function printKitchen(orderID){
            if(vm.singleItemKitchenMode == '0'){
                makeRestCall('api/print/invoce?objectid='+orderID+'&mode=kitchen&printerName='+vm.selectPrintKichen).then(function(data){

                },function(error){
                });
            }
            if(vm.singleItemKitchenMode == '1'){
                makeRestCall('api/print/singleKitchen?objectid='+orderID+'&mode=kitchen').then(function(data){


                },function(error){
                });
            }

        }

        function shallowCopy( original )
        {
            // First create an empty object with
            // same prototype of our original source
            var clone = Object.create( Object.getPrototypeOf( original ) ) ;

            var i , keys = Object.getOwnPropertyNames( original ) ;

            for ( i = 0 ; i < keys.length ; i ++ )
            {
                // copy each property into the clone
                Object.defineProperty( clone , keys[ i ] ,
                    Object.getOwnPropertyDescriptor( original , keys[ i ] )
                ) ;
            }

            return clone ;
        }
    }
})();
