(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderPosController', OrderPosController);

    OrderPosController.$inject = ['$uibModal','TableZone','$scope', '$localStorage','$q','$http','Principal','$state', 'OrderPos', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function OrderPosController($uibModal, TableZone, $scope, $localStorage, $q, $http, Principal, $state, OrderPos, ParseLinks, AlertService, paginationConstants, pagingParams) {

         var vm = this;
         vm.apiUrl = $localStorage.API_URL;
         vm.loadPage = loadPage;
         vm.predicate = pagingParams.predicate;
         vm.reverse = pagingParams.ascending;
         vm.transition = transition;
         vm.itemsPerPage = 5;
         vm.cleanTransition = cleanTransition;
         


         Principal.identity().then(function(account) {
            vm.currentAccount = account;
            vm.currentRole = 'ROLE_USER';
            for (var j = 0; j < vm.currentAccount.authorities.length; j++) {
                if(vm.currentAccount.authorities[j] == 'ROLE_ADMIN'){
                    vm.currentRole = 'ROLE_ADMIN';
                    break;
                }
                if(vm.currentAccount.authorities[j] == 'ROLE_SUPER_USER'){
                    vm.currentRole = 'ROLE_SUPER_USER';
                    break;
                }
            }

             if(vm.currentRole == 'ROLE_USER'){
               vm.filterWaiter = vm.currentAccount.login;
             }
             loadAllOrder();
             loadReport();
        });

         var makeRestCall   = function (url) {

            return $http.get(vm.apiUrl +url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

        

         makeRestCall('api/setting?type=PRINTORDER').then(function(data){
                vm.selectPrintOrder = data.data.setting;
            },function(error){
        });

         vm.dateformat = 'dd-MM-yyyy';
        vm.dateSearch = new Date();
        vm.dateSearch.setHours(0);
        vm.dateSearch.setMinutes(0);
        vm.dateSearch.setSeconds(0);

        vm.dateTo = new Date();
        vm.dateTo.setHours(0);
        vm.dateTo.setMinutes(0);
        vm.dateTo.setSeconds(0);

        $scope.open1 = function() {
            $scope.popup1.opened = true;
          };

        $scope.popup1 = {
            opened: false
          };

           $scope.open2 = function() {
            $scope.popup2.opened = true;
          };

        $scope.popup2 = {
            opened: false
          };

        vm.loadAllOrder = loadAllOrder;
        function loadAllOrder () {

            vm.total = 0;
            vm.totalWait = 0;

            var searchFrom = new Date(vm.dateSearch);
            var searchTo = null;
            

            var dataQuery = {
                searchFrom: searchFrom,
                searchTo: searchTo,
                now: true,
                sort: sort()
            };
            //console.log(dataQuery);
            OrderPos.query(dataQuery, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                result.push('startedAt,asc');
                return result;
            }
            function onSuccess(data, headers) {
                vm.totalItems =data.totalElements;
                    vm.queryCount = data.totalElements;
                    vm.orderPos = data.content;
                    //console.log(vm.bills);
                    vm.page = pagingParams.page;

                    for(var i=0;i<vm.orderPos.length;i++){
                        vm.total += vm.orderPos[i].totalValue;
                        if(vm.orderPos[i].orderStatus == 'WAIT'){
                            vm.totalWait += vm.orderPos[i].totalValue;
                        }
                    }

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
       
       

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

         function cleanTransition(){
            transition();
         }


         vm.print = function(mode,order){
            
          if(order.bid == null ){
               printBillMaster(order.id, mode);
           
          }else if(order.bid != null ){
               printBill(order.bid, mode);
           
          }
          
        }

        function printBillMaster(oId, mode){
              makeRestCall('/api/print/invocemaster?oId='+oId+'&mode='+mode+'&printerName='+vm.selectPrintOrder).then(function(data){
                 loadAllOrder();
            },function(error){
                alert("Xảy ra lỗi");
            });
        }

        vm.printBill = printBill;
        function printBill(billId,mode){
              makeRestCall('/api/print/invoce?objectid='+billId+'&mode='+mode+'&printerName='+vm.selectPrintOrder).then(function(data){
            },function(error){

            });
        }

        vm.delete = function(orderPos){
          if(orderPos.id != null){
            makeRestCall('/api/order-pos/stornos?orderId='+orderPos.id).then(function(data){
              loadAllOrder();
            },function(error){

            }); 
          }
                
        }

        function loadReport(){
          if(vm.currentRole == 'ROLE_SUPER_USER'){
             var dataQueryLoadAllTableZone = {
                            pageNo: null,
                            pageSize: null,
                            sortBy: sortLoadAllTableZone()
                        };

                        TableZone.query(dataQueryLoadAllTableZone, onSuccessLoadAllTableZone, onErrorLoadAllTableZone);
                        function sortLoadAllTableZone() {
                          var result = [];
                            result.push('tableZoneName:asc');
                            return result;
                        }
                        function onSuccessLoadAllTableZone(data, headers) {
                           vm.report = {};
                            var zones = data.content;
                            //console.log(data);
                            if(zones != null && zones.length >0){
                               var code = zones[0].tableZoneCode;
                               var token = code.split("@");

                               if(token.length == 5){
                                  vm.report.totalTurnOver = token[0];
                                  vm.report.cash = token[1];
                                  vm.report.card = token[2];
                                  vm.report.tipMoney = token[3];
                               }
                            }
                            
                        }
                        function onErrorLoadAllTableZone(error) {
                            AlertService.error(error.data.message);
                        }  
          }
                               
        }

        

        vm.printwaiter = printwaiter;
         function printwaiter(){
            var waiter = vm.filterWaiter;
            if(waiter == 'ALL'){
                waiter = 'Alles';
            }
            // print report
             makeRestCall('/api/print/reportForWaiter'
             ).then(function(data){
                        },function(error){

            });
            
         }

          // *** Modal Verify
        // ***
        vm.openModalVerify = function () {
                $uibModal.open({
                  templateUrl: 'app/entities/bill-master/modal-verify.html',
                  controller: function ($scope, $uibModalInstance) {
                    $scope.verified = false;
                    
                   $scope.t0003Config = {layout: 'custom',
                      customLayout: {
                        'default': [
                          '{accept} {b}',
                          '6 7 8 9',
                          '2 3 4 5',
                          '000 00 0 1'   
                        ]
                      },
                      display: {
                        'accept': 'OK'
                      },
                      kt: 'US International', showKbSelect: true,
                     numberPad: true, showVersion: true, usePreview: false, autoAccept : true, reposition : true,
                      position : {
                          // null (attach to input/textarea) or a jQuery object (attach elsewhere)
                          of : '#keyboard-wrapper',
                          my : 'center top',
                          at : 'center bottom',
                          // at2 is used when "usePreview" is false (centers keyboard at the bottom
                          // of the input/textarea)
                          at2: 'center bottom',
                          collision: 'flipfit flipfit'
                        },
                        beforeVisible: function(e, keyboard, el) {
                          var inModal = $(el).parents('.modal').length > 0;
                          if (inModal) {
                            keyboard.$keyboard.appendTo($(el).parents('.modal-body'));
                          }
                        }
                    }
          
                    $scope.cancel = function () {
                      $uibModalInstance.dismiss('cancel');
                    };
                    
                    $scope.tryPassword = function(){
                        //console.log($scope.passVerify);
                         makeRestCall('api/setting?type=PASS_2').then(function(data){
                          if(data != null){
                                vm.pass = data.data.setting;
                                if($scope.passVerify == vm.pass){
                                    vm.deleteReport(vm.reset);
                                    $scope.cancel();
                                }else {
                                    $scope.cancel();
                                }
                          }
                               
                            },function(error){
                        });
                         /*
                        
                        */
                    }
                }
            })

        };
        // ** end modal verify

        vm.deleteReport = deleteReport;
        function deleteReport(callback){
           makeRestCall('/api/table-zone/delete-all'
             ).then(function(data){
               loadReport();
               if(callback != null){
                 callback();
               }
            },function(error){

            });
        }
        
        vm.reset = reset;
        function reset(){
           makeRestCall('/api/delete-all-orders'
             ).then(function(data){
               loadAllOrder();
            },function(error){

            });
        }
    }
})();
