(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('BillMatxaPrintPreviewController', BillMatxaPrintPreviewController);

    BillMatxaPrintPreviewController.$inject = ['entity','Principal','$timeout','$scope','$rootScope','$state', 'BillMatxa', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','PosSetting','$http', '$q', '$localStorage','DateUtils'];

    function BillMatxaPrintPreviewController(entity, Principal, $timeout, $scope, $rootScope, $state, BillMatxa, ParseLinks, AlertService, paginationConstants, pagingParams, PosSetting,$http, $q, $localStorage,DateUtils) {

    	 var vm = this;
         vm.apiUrl = $localStorage.API_URL;
         vm.billMatxa = entity;

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
            vm.store = vm.currentAccount.store;
            //console.log(vm.store);
        });


        vm.back = function(){
             $state.go('order-matxa');
        }

        vm.print = function(){
            window.print();
            $state.go('order-matxa');
        }

    }
})();
