


(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('CustomerController', CustomerController);

    CustomerController.$inject = ['Auth','Principal','$q','$http','Upload','OrderPos','$timeout','$rootScope','$stateParams','$state', 'Customer', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$localStorage'];

    function CustomerController(Auth, Principal, $q, $http, Upload,OrderPos, $timeout, $rootScope, $stateParams,$state, Customer, ParseLinks, AlertService, paginationConstants, pagingParams,$localStorage) {

    	var vm = this;
        vm.$state = $state;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.cleanTransition = cleanTransition;
        vm.itemsPerPage = 50;
        vm.search = {};
       
        loadAll();

        var makeRestCall   = function (url) {
            return $http.get($localStorage.API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

       Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function loadAll () {
            var dataQuery = {
                pageNo: pagingParams.page,
                pageSize: vm.itemsPerPage,
                sortBy: sort()
            };
            
            if (!angular.isUndefined(pagingParams.searchValue)){
                    dataQuery.searchValue = pagingParams.searchValue;
                    vm.searchData = pagingParams.searchValue;
                
            }
            else{
                if (!angular.isUndefined(pagingParams.customerName)){
                    dataQuery.customerName = pagingParams.customerName;
                    vm.search.customerName = pagingParams.customerName;
                }
               
                if (!angular.isUndefined(pagingParams.customerPhone)){
                    dataQuery.customerPhone = pagingParams.customerPhone;
                    vm.search.customerPhone = pagingParams.customerPhone;
                }
                
               
            
           }
            //console.log(dataQuery);
            Customer.query(dataQuery, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }
            function onSuccess(data, headers) {
                vm.totalItems =data.totalElements;
                vm.queryCount = data.totalElements;
                vm.customers = data.content;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var query = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                searchValue: vm.searchData,
                customerName: vm.search.customerName,
                customerPhone: vm.search.customerPhone
            }
            
            $state.transitionTo($state.$current, query);
        }
        
        function cleanTransition(){
            vm.searchData = null;
            vm.search.customerName = null;
            vm.search.customerPhone  = null;
        
            transition();
        }

         vm.selectCustomer = function(customer){
            vm.currentCustomerId = customer.id;
         }

         
       vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }
    }
})();
