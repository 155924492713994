(function() {
	'use strict';

	angular.module('xposApp').controller('ThuchiDialogController',
			ThuchiDialogController);

	ThuchiDialogController.$inject = ['Config','Principal', '$timeout', '$scope', '$stateParams','$state','entity', 'Thuchi', 'Upload', '$localStorage'];

	function ThuchiDialogController(Config, Principal, $timeout, $scope, $stateParams,$state,entity, Thuchi, Upload, $localStorage) {
		var vm = this;
		vm.thuchi = entity;
		
		
		//console.log(vm.thuchi);
		vm.apiUrl = $localStorage.API_URL;
		vm.save = save;
		$timeout(function() {
			angular.element('.form-group:eq(0)>input').focus();
		});


		getAccount();
        function getAccount(callback) {
            Principal.identity().then(function (account) {
                vm.account = account;
                // $rootScope.flag = vm.account.langKey;
                vm.isAuthenticated = Principal.isAuthenticated;
                 if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
                 }

                 //console.log(vm.account);
                 if(callback != null){
                 	callback();
                 }
                 if(vm.thuchi.id == null){
					vm.thuchi.inputAt = new Date();
					vm.thuchi.person = vm.account.login;
				}
            });
        }

		function save() {
			_save();

			function _save(){
				if(vm.thuchi.thu == null && vm.thuchi.chi == null){
					alert("Phải nhập khoản thu hoặc chi hoặc cả 2");
				}else{
					vm.isSaving = true;
					if (vm.thuchi.id !== null) {
						//console.log(vm.thuchi);
						Thuchi.update(vm.thuchi, onSaveSuccess, onSaveError);
					} else {
						vm.thuchi.store = vm.account.store;
						Thuchi.save(vm.thuchi, onSaveSuccess, onSaveError);
					}	
				}
				
			}
			
			function onSaveSuccess(result) {
				$state.go('thuchi');
				vm.isSaving = false;
			}

			function onSaveError(result) {
				
				if(result.status == 400){
					alert("Lỗi");
				}
				vm.isSaving = false;
			}
		}

		
		

		vm.back = function(){
			$state.go('thuchi');
		}

		
	}
})();
