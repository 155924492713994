(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['Store','$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService'];

    function UserManagementDialogController (Store, $stateParams, $uibModalInstance, entity, User, JhiLanguageService) {
        var vm = this;

         vm.authorities = ['ROLE_USER','ROLE_ADMIN', 'ROLE_SUPER_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;

        getAllStore();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError (data) {
            vm.isSaving = false;
            alert(data.statusText);
        }

        function save () {
            vm.isSaving = true;
            vm.user.langKey = 'en';
            if (vm.user.id !== null) {
                 console.log(vm.user);
                User.update(vm.user, onSaveSuccess, onSaveError);

            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function getAllStore(){
                var dataQuery = {
                   active: 1
                };

                // get Stores
                Store.query(dataQuery, onSuccessGetStore, onErrorGetStore);
                function sort() {
                    var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                    return result;
                }
                function onSuccessGetStore(data, headers) {
                     vm.stores = data.content;
                    
                }
                function onErrorGetStore(error) {
                    AlertService.error(error.data.message);
                }
        }


    }
})();
