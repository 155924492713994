(function() {
	'use strict';

	angular.module('xposApp').controller('OrderPosSplitController',
			OrderPosSplitController);

	OrderPosSplitController.$inject = [ '$state', '$rootScope','entity','$stateParams', 'previousState', 'AlertService', 'OrderPos', 'Principal' ,'Customer','Bill','PosSetting', '$http', '$q','$localStorage'];

	function OrderPosSplitController($state, $rootScope,entity, $stateParams,previousState, AlertService, OrderPos, Principal, Customer, Bill, PosSetting, $http, $q, $localStorage) {

		var vm = this;
		vm.previousState = previousState.name;
		vm.currentOrderId = $stateParams.order;
		var API_URL = $localStorage.API_URL;
		vm.currentOrder = entity;
		vm.switchItem = switchItem;
		vm.removeSplitting = removeSplitting;
		vm.back = back;
		vm.changeNumberOfCustomer = changeNumberOfCustomer;
		vm.selectCustomer = selectCustomer;
		vm.rightView = null;
		vm.paymentView = null;
		vm.customerView = null;
		vm.isPaymentSplitting = true;
		vm.searchCustomer = searchCustomer;
		vm.openPaymentSplitting = openPaymentSplitting;
		vm.loadAllCustomer = loadAllCustomer;
		vm.cancelSplitMeal = cancelSplitMeal;

		vm.cancelSplitting = cancelSplitting;
		vm.typeNumber = typeNumber;
          vm.paymentSplitting = paymentSplitting;
          vm.openPaymentCurrentOrder = openPaymentCurrentOrder;

      vm.closeSelectCustomer = closeSelectCustomer;
      var firstClick = false;
	   vm.paymnetMethod = "CASH";

		initSplittingOrder();

		//print region
        // *****
				var makeRestCall   = function (url) {
						return $http.get(API_URL+url)
								.then(function (response) {
									return response;
								}, function (response) {
										// something went wrong
										return $q.reject(response.data);
								});
				};

				makeRestCall('api/setting?type=PRINTORDER').then(function(data){
                vm.selectPrintOrder = data.data.setting;
            },function(error){
        });

				makeRestCall('api/setting?type=PRINTA4').then(function(data){
                vm.selectPrintA4 = data.data.setting;
            },function(error){
        });



        var makeRestCall   = function (url) {
            return $http.get(API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

				function printBill(mode, billId){
            if(mode == 'BILL'){
                makeRestCall('api/print/invoce?objectid='+billId+'&mode=bill&printerName='+vm.selectPrintOrder).then(function(data){
                },function(error){

                });
            }
            if(mode == 'EVIDENCE'){
                makeRestCall('api/print/invoce?objectid='+billId+'&mode=evidence&printerName='+vm.selectPrintOrder).then(function(data){
                },function(error){

                });
            }
            if(mode == 'BILLA4'){
                makeRestCall('api/print/a4?objectid='+billId+'&mode=billa4&printerName='+vm.selectPrintA4).then(function(data){
                },function(error){

                });
            }

        }
        // end Print function


		function initSplittingOrder(){
			vm.splittingOrder = {};
			vm.splittingOrder.id= null;
			vm.splittingOrder.customerDTO = {};
			vm.splittingOrder.customerDTO = shallowCopy(vm.currentOrder.customerDTO);
			vm.splittingOrder.numberGuest = vm.currentOrder.numberGuest;
			vm.splittingOrder.tablePos = vm.currentOrder.tablePos;
			vm.splittingOrder.startedAt =  vm.currentOrder.startedAt;
			vm.splittingOrder.waiter = vm.currentOrder.waiter;
			vm.splittingOrder.listProducts = [];
			vm.splittingOrder.orderStatus = "INPROGRESS";
			vm.splittingOrder.splitOrder = "YES";

            vm.currentOrder.orderStatus = "INPROGRESS";
		}


		function searchCustomer(){
			vm.rightView = true;
			vm.customerView = true;
			loadAllCustomer();

		}
		function closeSelectCustomer (){
			vm.rightView = null;
			vm.customerView = null;
		}

		function openPaymentSplitting(){
			firstClick = false;
			vm.rightView = true;
			vm.paymentView = true;
			vm.isPaymentSplitting = true;
		    vm.cash = vm.splittingOrder.totalValue;
		    vm.charge = parseFloat(vm.cash) - parseFloat(vm.splittingOrder.totalValue);
		}
		function cancelSplitting(){
			vm.rightView = null;
			vm.paymentView= null;
			vm.isPaymentSplitting = true;
		}

		function openPaymentCurrentOrder(){
			firstClick = false;
			vm.rightView = true;
			vm.paymentView = true;
			vm.isPaymentSplitting = false;
		    vm.cash = vm.currentOrder.totalValue;
		    vm.charge = parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue);
		}


		function switchItem(orderedProduct){
			// 1.
			// update current Order
            
			var indexCurrent = null;
        	for (var i = 0; i < vm.currentOrder.listProducts.length; i++) {
    		    if(vm.currentOrder.listProducts[i].product.productName == orderedProduct.product.productName){
    		    	indexCurrent = i;
    		    	break;
    		    }
    		}
        	if(indexCurrent != null && indexCurrent != -1){
        		vm.currentOrder = changeQuantityOrderedProduct(shallowCopy(vm.currentOrder), 'Slice', indexCurrent,1);
        		if(vm.currentOrder.listProducts[indexCurrent].quantity <= 0){
        			vm.currentOrder.listProducts.splice(indexCurrent, 1);
        		}
        	}
        	// 2.
        	// update splitting order
        	var flag_check_exist = false;
    		for (var i = 0; i < vm.splittingOrder.listProducts.length; i++) {
    		    if(vm.splittingOrder.listProducts[i].product.productName == orderedProduct.product.productName){
    		    	vm.splittingOrder = changeQuantityOrderedProduct(shallowCopy(vm.splittingOrder),'Add', i,1);
    		    	flag_check_exist = true;
    		    	break;
    		    }
    		}

    		if(flag_check_exist == false){
    			var updatingProduct = {};
    			var product = shallowCopy(orderedProduct.product);
    			    updatingProduct.id = product.id;
                    updatingProduct.productName = product.productName;
                    updatingProduct.productCode = product.productCode;
                    updatingProduct.productPurchasePrice = product.productPurchasePrice;
                    updatingProduct.productSalePrice = product.productSalePrice;
                    updatingProduct.productCurrencyUnit = product.productCurrencyUnit;
                    updatingProduct.productType = product.productType;
                    updatingProduct.tax = shallowCopy(product.tax);
                    updatingProduct.productCategory = shallowCopy(product.productCategory);
                    var taxRate = orderedProduct.taxRate;
                    if(taxRate == null){
                        taxRate = 0.19;
                    }

    			vm.splittingOrder.listProducts.push({quantity:1, taxRate: taxRate,  product: updatingProduct});
		    	vm.splittingOrder = computeOrderedTotals(shallowCopy(vm.splittingOrder));
		    }
            //console.log(vm.splittingOrder);

		}

		function removeSplitting(orderedProduct){
			// 1.
			// update current Order
			var flag_check_exist = false;
    		for (var i = 0; i < vm.currentOrder.listProducts.length; i++) {
    		    if(vm.currentOrder.listProducts[i].product.productName == orderedProduct.product.productName){
    		    	vm.currentOrder = changeQuantityOrderedProduct(shallowCopy(vm.currentOrder), 'Add', i,1);
    		    	flag_check_exist = true;
    		    	break;
    		    }
    		}
    		if(flag_check_exist == false){
    			var updatingProduct = {};
    			var product = shallowCopy(orderedProduct.product);
    			 updatingProduct.id = product.id;
                    updatingProduct.productName = product.productName;
                    updatingProduct.productCode = product.productCode;
                    updatingProduct.productPurchasePrice = product.productPurchasePrice;
                    updatingProduct.productSalePrice = product.productSalePrice;
                    updatingProduct.productCurrencyUnit = product.productCurrencyUnit;
                    updatingProduct.productType = product.productType;
                    updatingProduct.tax = shallowCopy(product.tax);
                    updatingProduct.productCategory = shallowCopy(product.productCategory);
                    var taxRate = 0.19;
                    if(updatingProduct.tax != null && updatingProduct.tax.taxRate != null){
                        taxRate = updatingProduct.tax.taxRate;
                    }

    			vm.currentOrder.listProducts.push({quantity:1, taxRate: taxRate, product: updatingProduct});
    			vm.currentOrder = computeOrderedTotals(shallowCopy(vm.currentOrder));
    		}

        	// 2.
        	// update splitting order
        	var indexSplitting = null;
        	for (var i = 0; i < vm.splittingOrder.listProducts.length; i++) {
    		    if(vm.splittingOrder.listProducts[i].product.productName == orderedProduct.product.productName){
    		    	indexSplitting = i;
    		    	break;
    		    }
    		}
        	if(indexSplitting != null && indexSplitting != -1){
        		vm.splittingOrder = changeQuantityOrderedProduct(shallowCopy(vm.splittingOrder), 'Slice', indexSplitting,1);
        		if(vm.splittingOrder.listProducts[indexSplitting].quantity <= 0){
        			vm.splittingOrder.listProducts.splice(indexSplitting, 1);
        		}

        	}
		}

		function back() {
			//deleteCurrentOrder(vm.splittingOrder.id);
			OrderPos.get({ id:  vm.currentOrder.id  }, onSuccessLoadCurrentOrder, onErrorLoadCurrentOrder);
            function onSuccessLoadCurrentOrder(data, headers) {
                vm.currentOrder = data;
                $rootScope.$broadcast('updateCurrentOrder', vm.currentOrder);
    			makeRestCall('api/setting?type=STYLE').then(function(data){
                          if(data.data == null || data.data.setting == 'Default'){
                              $state.go('make-order');
                          }
                          else if(data.data == null || data.data.setting == 'Fast'){
                              $state.go('order-fast-advance');
                          }
                      },function(error){
                              $state.go('home');
                });
            }
            function onErrorLoadCurrentOrder(error) {
                AlertService.error(error.data.message);
            }

		}
		//***
        // Change number of customer
        function changeNumberOfCustomer(operator){
        	if(vm.splittingOrder.numberGuest != null && vm.splittingOrder.numberGuest != 'undefined'){
        		if(operator == 'Add'){
        			vm.splittingOrder.numberGuest += 1;
            	}
            	if(operator == 'Sub'){
            		vm.splittingOrder.numberGuest -= 1;
            		if(vm.splittingOrder.numberGuest <= 0){
            			vm.splittingOrder.numberGuest = 1;
            		}
            	}
        	}

        }

        // ***
		// Load all Customers resource
		function loadAllCustomer() {
			var dataQueryLoadAllCustomer = {
				pageNo : null,
				pageSize : null,
				sortBy : sortLoadAllCustomer()
			};
			if(vm.search != null && vm.search != ''){
				console.log(vm.search);
				dataQueryLoadAllCustomer.searchOr =true;
				dataQueryLoadAllCustomer.customerName = vm.search;
				dataQueryLoadAllCustomer.customerAddress = vm.search;
				dataQueryLoadAllCustomer.customerTaxCode = vm.search;
				dataQueryLoadAllCustomer.customerPhone = vm.search;
				dataQueryLoadAllCustomer.customerMail = vm.search;
			}


			Customer.query(dataQueryLoadAllCustomer, onSuccessLoadAllCustomer,
					onErrorLoadAllCustomer);
			function sortLoadAllCustomer() {
				var result = [];
				result.push('customerName:asc');
				return result;
			}
			function onSuccessLoadAllCustomer(data, headers) {
				vm.customers = data.content;
				// console.log(vm.customers);
			}
			function onErrorLoadAllCustomer(error) {
				AlertService.error(error.data.message);
			}
		}

		function selectCustomer(customer){
			vm.splittingOrder.customerDTO = customer;
			vm.rightView = null;
			vm.customerView = null;
		}

		function typeNumber(mode,number){
        	if(mode =='Number' && number != null){
        		if(firstClick == false || vm.cash ==0){
            		firstClick = true;
            		vm.cash = '';
            	}

            	vm.cash = String(vm.cash) + String(number);
            	if(number != '.'){
            		if(vm.isPaymentSplitting == true){
            			vm.charge = Math.round((parseFloat(vm.cash) - parseFloat(vm.splittingOrder.totalValue)) * 100) / 100;
            		}
            		if(vm.isPaymentSplitting == false){
            			vm.charge = Math.round((parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue)) * 100) / 100;
            		}

            	}
            	if(number == '.'){
            		vm.charge = 'N/A';
            	}
        	}else
        	if(mode =='Delete'){
        		if(vm.isPaymentSplitting == true){
        			vm.charge = Math.round((0 - parseFloat(vm.splittingOrder.totalValue)) * 100) / 100;
        		}
        		if(vm.isPaymentSplitting == false){
        			vm.charge = Math.round((0 - parseFloat(vm.currentOrder.totalValue)) * 100) / 100;
        		}
        		vm.cash = '0';
        	}else
        	if(mode =='Slice'){
        		firstClick = true;
        		vm.cash = String(vm.cash).slice(0,-1);
        		vm.charge = Math.round((parseFloat(vm.cash) - parseFloat(vm.splittingOrder.totalValue)) * 100) / 100;
        	}

        }

		function cancelSplitMeal(){
			mergeProducts();
			initSplittingOrder();
		}
        // ***
        // Payment
        function paymentSplitting(mode){
        	if(vm.isPaymentSplitting == true){
        		if(Number(vm.cash) > 0){
                	vm.splittingOrder.splitOrder = "YES";
                    vm.splittingOrder.cash = vm.cash;
    				vm.splittingOrder.paymentMethod = vm.paymnetMethod;
    				if(vm.paymnetMethod == 'CASH'){
    					vm.splittingOrder.chargeMoney = vm.charge;
    					vm.splittingOrder.tipMoney = 0;
    				}
    				if(vm.paymnetMethod == 'CARD'){
    					vm.splittingOrder.chargeMoney = 0;
    					vm.splittingOrder.tipMoney = vm.charge;
                        
    				}
                    vm.splittingOrder.place = "IN-HOUSE";
                	// update currentOrder to db
                  //console.log("vm.splittingOrder");
                  //console.log(vm.splittingOrder);
                	OrderPos.save(vm.splittingOrder, onSaveSplittingOrderSuccess, onSaveSplittingOrderError);
                	function onSaveSplittingOrderSuccess (data) {
                         console.log(data);
                		insertNewBill(data, mode);
                		initSplittingOrder();
                		vm.rightView = null;
                		updateCurrentOrderToDB();
            	    }

            	    function onSaveSplittingOrderError () {
            	    	AlertService.error("Update Order Error");
            	    }
                }
        	}
        	if(vm.isPaymentSplitting == false){
        		if(Number(vm.cash) > 0){
                	vm.currentOrder.splitOrder = "NO";
                    vm.currentOrder.cash = vm.cash;
    				vm.currentOrder.paymentMethod = vm.paymnetMethod;
    				if(vm.paymnetMethod == 'CASH'){
    								vm.currentOrder.chargeMoney = vm.charge;
    								vm.currentOrder.tipMoney = 0;
    				}
    				if(vm.paymnetMethod == 'CARD'){
    								vm.currentOrder.chargeMoney = 0;
    								vm.currentOrder.tipMoney = vm.charge;
    			     }
                     vm.currentOrder.place = "IN-HOUSE";
                	//console.log(vm.currentOrder);
                	// update currentOrder to db
                	OrderPos.update(vm.currentOrder, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                	function onSaveCurrentOrderSuccess (data) {
                		insertNewBill(data, mode, callbackFinishSplitBill);
                		vm.rightView = null;
            	    }

            	    function onSaveCurrentOrderError () {
            	    	AlertService.error("Update Order Error");
            	    }
                    // private function
                    function callbackFinishSplitBill(){
                             //console.log("callback: callbackFinishSplitBill");
    						  $localStorage["currentOrder.id"] = null;
                              localStorage["jhi-currentOrder.id"] = null;
                             $state.go('order-fast-advance');
                    }
                }
        	}

        }

		// *** Private Functions
		// *****
		// private function
		 function changeQuantityOrderedProduct(order, mode, i, added){
	        	if(mode == 'Add'){
	        		order.listProducts[i].quantity = order.listProducts[i].quantity + added;
	        	}else
	        	if(mode == 'Slice'){
	        		order.listProducts[i].quantity = order.listProducts[i].quantity - added;
	        	}
	        	order.listProducts[i].totalTax = Math.round((order.listProducts[i].quantity*order.listProducts[i].tax) * 100) / 100;
	        	order.listProducts[i].totalDiscount = Math.round((order.listProducts[i].quantity*order.listProducts[i].discount) * 100) / 100;
	        	order.listProducts[i].totalMoney = Math.round((order.listProducts[i].quantity*order.listProducts[i].product.productPurchasePrice) * 100) / 100;

		    	order = computeOrderedTotals(shallowCopy(order));
		    	return order;
	    }

        // private function
        function computeOrderedTotals(order){
            var totalValue = 0;
        	for (var i = 0; i < order.listProducts.length; i++) {
        		totalValue += order.listProducts[i].quantity * order.listProducts[i].product.productSalePrice;
    		}

        	order.totalValue = Math.round(totalValue * 100.0) / 100.0;

        	return order;
        }

        function updateCurrentOrderToDB(){
        	OrderPos.update(vm.currentOrder, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
        	function onSaveCurrentOrderSuccess (data, headers) {
        		vm.currentOrder = data;
    	    }

    	    function onSaveCurrentOrderError (error) {
    	    	AlertService.error(error.data.message);
    	    }
        }

        function updateSplittingOrderToDB(){
        	OrderPos.update(vm.splittingOrder, onSaveSplittingOrderSuccess, onSaveSplittingOrderError);
        	function onSaveSplittingOrderSuccess (data, headers) {
        		vm.splittingOrder = data;
    	    }

    	    function onSaveSplittingOrderError (error) {
    	    	AlertService.error(error.data.message);
    	    }
        }

        // private function
		function createNewtOrderToDB() {
			OrderPos.save(vm.splittingOrder, onCreateNewtOrderSuccess,
					onCreateNewtOrderSuccess);

			function onCreateNewtOrderSuccess(result) {
				vm.splittingOrder = result;
			}

			function onSaveCurrentOrderError(error) {
				AlertService.error(error.data.message);
			}
		}

		function deleteCurrentOrder(orderID){
        	OrderPos.delete({ id: orderID  }, onDeleteSuccess);
        	function onDeleteSuccess(){
        	}

        }

		function mergeProducts(){
			//console.log(vm.splittingOrder);
			//console.log(vm.currentOrder);
			if(vm.splittingOrder.listProducts != null && vm.splittingOrder.listProducts.length >0){
				for(var j = 0; j < vm.splittingOrder.listProducts.length; j++){
	            	var targetProduct = vm.splittingOrder.listProducts[j];
	            	var flag_check_exist = false;
	            	for (var i = 0; i < vm.currentOrder.listProducts.length; i++) {
	        		    if(vm.currentOrder.listProducts[i].product.productName == targetProduct.product.productName){
	        		    	vm.currentOrder = changeQuantityOrderedProduct(shallowCopy(vm.currentOrder), 'Add',i, targetProduct.quantity);
	        		    	flag_check_exist = true;
	        		    	break;
	        		    }
	        		}
	        		if(flag_check_exist == false){
	        			vm.currentOrder.listProducts.push(targetProduct);
	        			vm.currentOrder = computeOrderedTotals(shallowCopy(vm.currentOrder));
	        		}
	            }
				//updateCurrentOrderToDB();
			}

			//deleteCurrentOrder(vm.splittingOrder.id);
			vm.splittingOrder = null;
		}

		 function insertNewBill(order, mode, callback){
	        	// insert new bill to db
	        	var bill = {}
	        	bill.id = null;
	        	bill.code = null;
	        	bill.tablePos = order.tablePos;
	        	bill.waiter = order.waiter;
	        	bill.startedAt = order.startedAt;
	        	bill.endAt = new Date();
	        	bill.orderId = order.id;
	        	bill.numberGuest = order.numberGuest;
	        	bill.customerDTO = order.customerDTO;
	        	bill.listProducts = order.listProducts;
	        	bill.totalValue = order.totalValue;
	        	bill.discountValue = order.totalDiscount;
	        	bill.totalVAT = order.totalVAT;
	        	bill.subTotal = order.subTotal;

	        	bill.cash = vm.cash;
				bill.paymentMethod = vm.paymnetMethod;
    			if(vm.paymnetMethod == 'CASH'){
    	            bill.chargeMoney = vm.charge;
    	            bill.tipMoney = 0;
    	       }
    	       if(vm.paymnetMethod == 'CARD'){
    	            bill.chargeMoney = 0;
    	            bill.tipMoney = vm.charge;
    	          }


	        	bill.splitOrder = order.splitOrder;
	        	bill.requireFinish = true;
	        	Bill.save(bill, onSaveBillSuccess, onSaveBillError);

	        	function onSaveBillSuccess(data){
                    /* 1. finish order */
                    order.orderStatus = "FINISH";
                    order.endAt = new Date();
                    OrderPos.update(order, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                    function onSaveCurrentOrderSuccess (data) {

                    }

                    function onSaveCurrentOrderError () {
                    }
                    /* 2. print bill */
					if(mode == 'BILL' || mode == 'BILLA4' || mode=='EVIDENCE'){
	        			  printBill(mode, data.id);
	        		}
              if(callback != null){
                  callback();
              }
	        	}
	        	function onSaveBillError(){
	        		AlertService.error("Insert Bill Error");
	        	}
	        }

		 function shallowCopy( original )
	        {
		    	if(original == null || original == 'undefined'){
		    		return null;
		    	}
	            // First create an empty object with
	            // same prototype of our original source
	            var clone = Object.create( Object.getPrototypeOf( original ) ) ;

	            var i , keys = Object.getOwnPropertyNames( original ) ;

	            for ( i = 0 ; i < keys.length ; i ++ )
	            {
	                // copy each property into the clone
	                Object.defineProperty( clone , keys[ i ] ,
	                    Object.getOwnPropertyDescriptor( original , keys[ i ] )
	                ) ;
	            }

	            return clone ;
	        }

	}
})();
