(function() {
	'use strict';

	angular.module('xposApp').controller('TicketDialogController',
			TicketDialogController);

	TicketDialogController.$inject = ['Config','Principal', '$timeout', '$scope', '$stateParams','$state','entity', 'Ticket', 'Upload', '$localStorage'];

	function TicketDialogController(Config, Principal, $timeout, $scope, $stateParams,$state,entity, Ticket, Upload, $localStorage) {
		var vm = this;
		vm.ticket = entity;
		//console.log(vm.ticket);
		vm.apiUrl = $localStorage.API_URL;
		vm.save = save;
		$timeout(function() {
			angular.element('.form-group:eq(0)>input').focus();
		});


		getAccount();
        function getAccount(callback) {
            Principal.identity().then(function (account) {
                vm.account = account;
                // $rootScope.flag = vm.account.langKey;
                vm.isAuthenticated = Principal.isAuthenticated;
                 if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
                 }

                 //console.log(vm.account);
                 if(callback != null){
                 	callback();
                 }
            });
        }

		function save() {
			_save();

			function _save(){
				vm.isSaving = true;
				if (vm.ticket.id !== null) {
					//console.log(vm.ticket);
					Ticket.update(vm.ticket, onSaveSuccess, onSaveError);
				} else {
					vm.ticket.store = vm.account.store;
					Ticket.save(vm.ticket, onSaveSuccess, onSaveError);
				}
			}
			
			function onSaveSuccess(result) {
				$state.go('ticket');
				vm.isSaving = false;
			}

			function onSaveError(result) {
				
				if(result.status == 400){
					alert("Không được phép tạo phòng mới trùng tên với phòng cũ");
				}
				vm.isSaving = false;
			}
		}

		
		

		vm.back = function(){
			$state.go('ticket');
		}

		
	}
})();
