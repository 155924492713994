(function() {
    'use strict';
    angular
        .module('xposApp')
        .factory('Bill', Bill);

    Bill.$inject = ['$resource', 'DateUtils', '$localStorage'];

    function Bill ($resource, DateUtils, $localStorage) {
        var resourceUrl =  $localStorage.API_URL+'api/bills/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false,params: {searchFrom: null, searchTo: null}},
            'get': {
                method: 'GET',
                params: {searchFrom: null, searchTo: null},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startedAt = DateUtils.convertDateTimeFromServer(data.startedAt);
                        data.endAt = DateUtils.convertDateTimeFromServer(data.endAt);
                        data.createAt = DateUtils.convertDateTimeFromServer(data.createAt);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
