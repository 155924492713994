(function() {
    'use strict';

    angular
        .module('xposApp')
        .factory('Password', Password);

    Password.$inject = ['$resource', '$localStorage'];

    function Password($resource, $localStorage) {
        var service = $resource($localStorage.API_URL+'api/account/change-password', {}, {});

        return service;
    }
})();
