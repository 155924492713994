(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('PaymentDialogController', PaymentDialogController);

    PaymentDialogController.$inject = ['Auth','$state','$timeout', '$rootScope', '$stateParams', '$uibModalInstance','AlertService', 
    'entity', 'OrderPos','Bill','PosSetting', '$http', '$q','$localStorage'];

    function PaymentDialogController (Auth, $state, $timeout, $rootScope, $stateParams, $uibModalInstance, AlertService, entity,
     OrderPos, Bill,PosSetting, $http, $q, $localStorage) {
    	var vm = this;
        vm.apiUrl = $localStorage.API_URL;
        var API_URL = $localStorage.API_URL;
        vm.currentOrder = entity;
        vm.clear = clear;
        vm.typeNumber = typeNumber;
        vm.payment = payment;
        vm.paymnetMethod = "CASH";

        vm.cash = Math.round((Number(vm.currentOrder.totalValue) * 100) * 100.0) / 100.0;
        vm.charge = 0;
       
        //print region
        // *****
        var makeRestCall   = function (url) {
            return $http.get(API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };
        makeRestCall('api/setting?type=PRINTORDER').then(function(data){
                vm.selectPrintOrder = data.data.setting;
            },function(error){
        });
        makeRestCall('api/setting?type=PRINTA4').then(function(data){
                vm.selectPrintA4 = data.data.setting;
            },function(error){
        });
         makeRestCall('api/setting?type=SINGLE_KITCHEN').then(function(data){
            if(data.data != null){
                vm.singleItemKitchenMode = data.data.setting;
                }
            },function(error){
          });

        var makeRestCall   = function (url) {
            return $http.get(API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };
        function printBill(mode, billId){
            if(mode == 'BILL'){
                makeRestCall('api/print/invoce?objectid='+billId+'&mode=bill&printerName='+vm.selectPrintOrder).then(function(data){
                },function(error){

                });
            }
            if(mode == 'EVIDENCE'){
                makeRestCall('api/print/invoce?objectid='+billId+'&mode=evidence&printerName='+vm.selectPrintOrder).then(function(data){
                },function(error){

                });
            }
            if(mode == 'BILLA4'){
                makeRestCall('api/print/a4?objectid='+billId+'&mode=billa4&printerName='+vm.selectPrintA4).then(function(data){
                },function(error){

                });
            }

        }
        // payment region
        // ***
        vm.selectPaymentMethod = function(method){
            vm.paymnetMethod = method;
        }
        var firstClick = false;
        function typeNumber(mode,number){
        	if(mode =='Number' && number != null){
        		if(firstClick == false || vm.cash ==0){
            		firstClick = true;
            		vm.cash = '';
            	}

            	vm.cash = String(vm.cash) + String(number);
            	if(number != '.'){
            		//vm.charge = Math.round((parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue)) * 100) / 100;
                    var c = parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue * 100);
                    vm.charge = Math.round(c * 100.0) / 100.0;
            	}
            	if(number == '.'){
            		vm.charge = 'N/A';
            	}
        	}else if(mode == 'Total'){
                var a = Math.round((number*100) * 100.0) / 100.0;
                vm.cash = String(a);
                var b = parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue * 100);
                vm.charge = Math.round(b * 100.0) / 100.0;
            }
            else
        	if(mode =='Delete'){

        		//vm.charge = Math.round((0 - parseFloat(vm.currentOrder.totalValue)) * 100) / 100;
                 vm.charge = 0 - parseFloat(vm.currentOrder.totalValue * 100);
        		vm.cash = '0';
        	}else
        	if(mode =='Slice'){
        		firstClick = true;
        		vm.cash = String(vm.cash).slice(0,-1);
        		//vm.charge = Math.round((parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue)) * 100) / 100;
                 vm.charge = parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue * 100);
        	}

        }

        vm.typeMoneyIcon = function(cent){
            vm.cash += cent;
            vm.charge = parseFloat(vm.cash) - parseFloat(vm.currentOrder.totalValue * 100);
        }
        // ***
        // Payment
        function payment(mode){
        	
        	if(Number(vm.cash) > 0){
                //console.log("vm.cash: "+vm.cash);
                vm.currentOrder.requireFinish = '0';

                vm.currentOrder.cash = Number(vm.cash)/100;
                vm.currentOrder.paymentMethod = vm.paymnetMethod;

              if(vm.paymnetMethod == 'CASH'){
                vm.currentOrder.chargeMoney = Number(vm.charge)/100;
                vm.currentOrder.tipMoney = 0;
              }
              if(vm.paymnetMethod == 'CARD'){
                vm.currentOrder.chargeMoney = 0;
                vm.currentOrder.tipMoney = Number(vm.charge)/100;
                vm.currentOrder.requireFinish = '1';
              }


              
              if(mode == 'BILL' || mode == 'BILLA4' || mode=='EVIDENCE'){
                    vm.currentOrder.requireFinish = '1';
              }

             if(mode == 'WAIT'){
                vm.currentOrder.endAt = new Date();
                vm.currentOrder.orderStatus = 'WAIT';
             }
                // update currentOrder to db

               // console.log("Request Update Order (Khi thanh toan)")
               // console.log(JSON.stringify(vm.currentOrder, null, "    ") )
               OrderPos.get({ id: vm.currentOrder.id  }, onSuccessLoadCurrentOrder, onErrorLoadCurrentOrder);
                function onSuccessLoadCurrentOrder(data, headers) {
                    var dbOrder = shallowCopy(data);
                    if(dbOrder.totalValue != vm.currentOrder.totalValue){
                        alert("Warnung! Jemand anders hat diese Reihenfolge geändert");
                         Auth.logout();
                        $state.go('home');
                        $uibModalInstance.dismiss('cancel');
                    }else{
                        OrderPos.update(vm.currentOrder, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                        function onSaveCurrentOrderSuccess (data) {
                            //console.log(data);
                           
                            printKitchen(data.id);
                            if(mode != 'WAIT'){
                                insertNewBill(data,mode);
                             }
                            
                            vm.currentOrder = null;
                            $localStorage["currentOrder.id"] = null;
                            $rootScope.$broadcast('updateCurrentOrder', vm.currentOrder);
                            $uibModalInstance.dismiss('cancel');

                        }

                        function onSaveCurrentOrderError () {
                            alert("Diese Bestellung kann nicht aktualisiert werden!");
                            $uibModalInstance.dismiss('cancel');
                            //$state.go('make-order');
                        }
                    }

                }
                function onErrorLoadCurrentOrder(error) {
                    AlertService.error(error);
                }

                
           
            }

          
        }
        $timeout(function (){
            //angular.element('.in-money>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.orderPos.id !== null) {
                OrderPos.update(vm.orderPos, onSaveSuccess, onSaveError);
            } else {
                OrderPos.save(vm.orderPos, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('xposApp:orderPosUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function insertNewBill(order, mode){
        	// insert new bill to db
        	var bill = {};
        	bill.id = null;
        	bill.code = null;
        	bill.tablePos = order.tablePos;
        	bill.waiter = order.waiter;
        	bill.startedAt = order.startedAt;
        	bill.endAt = new Date();
        	bill.orderId = order.id;
        	bill.numberGuest = order.numberGuest;
        	bill.customerDTO = order.customerDTO;
        	bill.listProducts = order.listProducts;
        	bill.totalValue = order.totalValue;
        	bill.discountValue = order.totalDiscount;
        	bill.totalVAT = order.totalVAT;
        	bill.subTotal = order.subTotal;
        	bill.cash = Number(vm.cash) / 100;
        	bill.paymentMethod = vm.paymnetMethod;

          if(vm.paymnetMethod == 'CASH'){

            bill.chargeMoney = Number(vm.charge)/100;
            bill.tipMoney = 0;
          }
          if(vm.paymnetMethod == 'CARD'){
            bill.chargeMoney = 0;
            bill.tipMoney = Number(vm.charge)/100;
          }

        	bill.splitOrder = order.splitOrder;

            if(mode == 'BILL' || mode == 'BILLA4' || mode=='EVIDENCE'){
                bill.requireFinish = true;
            }
           // console.log("Request Create New Bill")
            //console.log(JSON.stringify(bill, null, "    ") )
        	Bill.save(bill, onSaveBillSuccess, onSaveBillError);

        	function onSaveBillSuccess(data){
                // 1. finish order
                order.orderStatus = "FINISH";
                order.endAt = new Date();
                OrderPos.update(order, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                function onSaveCurrentOrderSuccess (data) {
                }

                function onSaveCurrentOrderError (error) {
                    alert(error);
                }
                // 2. print bill
        		if(mode == 'BILL' || mode == 'BILLA4' || mode=='EVIDENCE'){

        			printBill(mode, data.id);
        		}

        	}
        	function onSaveBillError(){
        		AlertService.error("Insert Bill Error");
        	}
        }
        // ******
        // Print kitchen when finish
        function printKitchen(orderID){
            if(vm.singleItemKitchenMode == '0'){
                makeRestCall('api/print/invoce?objectid='+orderID+'&mode=kitchen&printerName='+vm.selectPrintKichen).then(function(data){

                },function(error){
                });
            }
            if(vm.singleItemKitchenMode == '1'){
                makeRestCall('api/print/singleKitchen?objectid='+orderID+'&mode=kitchen').then(function(data){


                },function(error){
                });
            }

        }

        function shallowCopy( original )
        {
            // First create an empty object with
            // same prototype of our original source
            var clone = Object.create( Object.getPrototypeOf( original ) ) ;

            var i , keys = Object.getOwnPropertyNames( original ) ;

            for ( i = 0 ; i < keys.length ; i ++ )
            {
                // copy each property into the clone
                Object.defineProperty( clone , keys[ i ] ,
                    Object.getOwnPropertyDescriptor( original , keys[ i ] )
                ) ;
            }

            return clone ;
        }
    }
})();
