(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderKaraokeController', OrderKaraokeController);

    OrderKaraokeController.$inject = ['$uibModal','Auth','$timeout','$state','$rootScope','$stateParams', 'Principal',
    'Product', 'ParseLinks', 'AlertService',
     'paginationConstants', 'pagingParams', 'PosSetting', '$http', '$q', '$scope','$localStorage', 
      'Bill','Room'];

    function OrderKaraokeController( $uibModal,Auth, $timeout, $state, $rootScope, $stateParams,Principal,
         Product, ParseLinks, AlertService, paginationConstants, 
        pagingParams, PosSetting, $http, $q, $scope, $localStorage,   Bill ,Room
        ) {

        var vm = this;
        vm.apiUrl = $localStorage.API_URL;
        var API_URL = $localStorage.API_URL;
        vm.selectedOrderedProductIndex = null;

        function makePutCall(url,data) {
            return $http.put(vm.apiUrl+url,data)
                .then(function (response) {
                  return response;
                }, function (response) {
                    return $q.reject(response.data);
                });
        };

       Principal.identity().then(function(account) {
            vm.currentAccount = account;
            loadAllRoom();
            loadAllProducts();
        });
       vm.loadAllRoom = loadAllRoom;
       function loadAllRoom(){
                var dataQuery = {
                    storeId: vm.currentAccount.store.id
                };
               
                // get Products
                Room.query(dataQuery, onSuccess, onError);
                function onSuccess(data, headers) {
                    vm.rooms = data.content;
                    //console.log(vm.rooms);
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
       }
       vm.selectRoom = function(room){
           //console.log(room);
           if(room.orderStatus == null || room.orderStatus == 'FINISH'){
             vm.currentOrder = {};
             vm.currentOrder.id= null;
             vm.currentOrder.store = vm.currentAccount.store;
             room.orderStatus = 'INPROGRESS';
             room.orderStartAt = new Date();
             vm.currentOrder.room = room;
             vm.currentOrder.startedAt = room.orderStartAt;
             vm.currentOrder.waiter = vm.currentAccount.login;
             Bill.save(vm.currentOrder, onSuccess,
                    onError);
            function onSuccess(result) {
                vm.currentOrder = result;
                console.log("1");
                console.log(vm.currentOrder);
                loadAllRoom();
            }
            function onError(error) {
                AlertService.error(error.data);
            }
           }else{
                Bill.get({ id: room.orderId  }, onSuccess, onError);
                function onSuccess(data, headers) {
                   vm.currentOrder = data;
                   console.log("2");
                   console.log(vm.currentOrder);
                   loadAllRoom();
                  //console.log(vm.currentOrder);
                }
                function onError(error) {
                    AlertService.error(error);
                }
           }
        }
       function loadAllProducts () {
                var dataQuery = {
                    storeId: vm.currentAccount.store.id
                };
               
                // get Products
                Product.query(dataQuery, onSuccessGetProduct, onErrorGetProduct);
                function sort() {
                    var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                    return result;
                }
                function onSuccessGetProduct(data, headers) {
                    vm.products = data.content;
                }
                function onErrorGetProduct(error) {
                    AlertService.error(error.data.message);
                }
        }

        vm.selectProduct = function(product){
            // if(product.quantity >0){
            //     if(vm.currentOrder != null && vm.currentOrder.discountValue <=0){
            //         vm.openModalUpdateProductNumber('ADD', product, null);
            //    }else{
            //       if(vm.currentOrder.discountValue >0){
            //           alert("Không được phép sửa đồ khi giá trị khuyến mại > 0");
            //       }
            //    }
            //   }else{
            //     alert("Kho đã hết mặt hàng này. Không thể chọn");
            //   }  
            // TuanLA Update
          if(product.quantity >0){
            if(vm.currentOrder != null ){
                vm.openModalUpdateProductNumber('ADD', product, null);
            }
          }else{
            alert("Kho đã hết mặt hàng này. Không thể chọn");
          }
           
        }

        vm.changeQuantityProduct = function(orderedProduct){
        //     if(vm.currentOrder != null && vm.currentOrder.discountValue <=0){
        //         vm.openModalUpdateProductNumber('UPDATE', null, orderedProduct);
        //    }else{
        //     if(vm.currentOrder.discountValue >0){
        //         alert("Không được phép sửa đồ khi giá trị khuyến mại > 0");
        //     }
        //    }
        /** Not check discount Tuan LA */
            if(vm.currentOrder != null){
                vm.openModalUpdateProductNumber('UPDATE', null, orderedProduct);
            }

        }

        vm.openModalUpdateProductNumber = function (mode, product, orderedProduct) {
            if(vm.currentOrder != null){
                $uibModal.open({
                  templateUrl: 'app/entities/order-fast-advance/modal-update-product.html',
                  controller: function ($scope, $uibModalInstance) {
                    $scope.productName = null;;
                    if(product != null){
                        $scope.productName = product.productName;
                    }else if(orderedProduct != null){
                        $scope.productName = orderedProduct.name;
                        $scope.number = String(orderedProduct.quantity);
                    }

                    $scope.listNumber = [0,1,2,3,4,5,6,7,8,9];
                    $scope.selectNumber = function(n){
                       if($scope.number == null){
                            $scope.number =  String(n);
                       }else{
                            $scope.number = String($scope.number) + String(n);
                       }
                        
                       var n2 = Number($scope.number);
                        $scope.number = String(n2);   
                        
                    }

                    $scope.deleteNumber = function(){
                         $scope.number = null;
                    }

                    $scope.updateNumber = function(){
                        var updatedProductDTO = {};
                        updatedProductDTO.billId = vm.currentOrder.id;
                        updatedProductDTO.mode = mode;
                        if(mode == 'ADD'){
                            updatedProductDTO.productId = product.id;
                            updatedProductDTO.number = Number($scope.number);
                        }else if(mode == 'UPDATE'){
                            updatedProductDTO.orderedProductId = orderedProduct.orderedProductId;
                            updatedProductDTO.number = Number($scope.number);
                        }
                        

                        makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                            vm.currentOrder = data.data;
                            loadAllProducts();
                            //console.log(data);
                            $uibModalInstance.close(true);
                        },function(error){
                          
                        });
                    }

                    $scope.cancel = function () {
                      $uibModalInstance.dismiss('cancel');
                    };
                  }
                })
                 }
        };

        vm.openModalDiscount = function () {
            if(vm.currentOrder != null){
                $uibModal.open({
                  templateUrl: 'app/entities/order-fast-advance/modal-discount.html',
                  controller: function ($scope, $uibModalInstance) {

                    $scope.listNumber = [0,1,2,3,4,5,6,7,8,9];
                    $scope.selectNumber = function(n){
                       if($scope.number == null){
                            $scope.number =  String(n);
                       }else{
                            $scope.number = String($scope.number) + String(n);
                       }
                        
                       var n2 = Number($scope.number);
                        $scope.number = String(n2);   
                        
                    }

                    $scope.deleteNumber = function(){
                         $scope.number = null;
                    }

                    $scope.discount = function(){
                        var updatedProductDTO = {};
                        updatedProductDTO.billId = vm.currentOrder.id;
                        updatedProductDTO.mode = "DISCOUNT";
                        updatedProductDTO.orderDiscount = Number($scope.number);
                        

                        makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                            vm.currentOrder = data.data;
                            $uibModalInstance.close(true);
                        },function(error){
                        });
                    }

                    $scope.cancel = function () {
                      $uibModalInstance.dismiss('cancel');
                    };
                  }
                })
                 }
        };
       
        vm.selectItem = function(orderedProductId, quantity){
            vm.selectedOrderedProductIndex  = orderedProductId;
            vm.selectedOrderedProductQuantity = quantity;
        }

        vm.deleteSelectedOrderedItem = function(){
            if(vm.currentOrder != null && vm.selectedOrderedProductIndex != null){
                var txt =  "Bạn thực sự muốn xóa đồ này?";
                var r = confirm(txt);
                if (r == true) {
                    var updatedProductDTO = {};
                    updatedProductDTO.billId = vm.currentOrder.id;
                    updatedProductDTO.mode = "DELETE";
                    updatedProductDTO.orderedProductId = vm.selectedOrderedProductIndex;
                    updatedProductDTO.number = Number(vm.selectedOrderedProductQuantity);
                     makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                        vm.currentOrder = data.data;
                        vm.selectedOrderedProductIndex  = null;
                        vm.selectedOrderedProductQuantity = null;    
                        loadAllProducts();   
                    },function(error){
                    });
                 }
            }
        }

        vm.deleteCurrentOrder = function(){
             if(vm.currentOrder != null){
                var txt =  "Bạn thực sự muốn hủy phòng này?";
                var r = confirm(txt);
                if (r == true) {
                    var updatedProductDTO = {};
                    updatedProductDTO.billId = vm.currentOrder.id;
                    updatedProductDTO.mode = "DELETE-ORDER";
                     makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                        alert("Hủy phòng thành công");
                        loadAllRoom();
                        loadAllProducts();
                        vm.currentOrder = null;
                        vm.selectedOrderedProductIndex  = null;
                        vm.selectedOrderedProductQuantity = null;       
                    },function(error){
                    });
                 }
            }
        }

        vm.checkout = function(){
            if(vm.currentOrder != null){
                var txt =  "Bạn thực sự muốn kết thúc và thanh toán phòng này?";
                var r = confirm(txt);
                if (r == true) {
                    var updatedProductDTO = {};
                    updatedProductDTO.billId = vm.currentOrder.id;
                    updatedProductDTO.mode = "CHECKOUT";
                     makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                        vm.currentOrder = null;
                        vm.selectedOrderedProductIndex  = null;
                        vm.selectedOrderedProductQuantity = null;   
                        $state.go('bill-print-preview',{id: data.data.id});
                    },function(error){
                    });
                 }
            }
        }

        vm.discountRoom = function(){
          if(vm.currentOrder != null){
                        var updatedProductDTO = {};
                        updatedProductDTO.billId = vm.currentOrder.id;
                        updatedProductDTO.mode = "DISCOUNT-ROOM";
                        
                       // console.log(updatedProductDTO);
                        makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                            vm.currentOrder = data.data;
                            alert("Đã thiết lập miễn phí giờ hát cho đơn hàng này thành công!");
                        },function(error){
                        });
          }
        }

        vm.cancelDiscountRoom = function(){
          if(vm.currentOrder != null){
                        var updatedProductDTO = {};
                        updatedProductDTO.billId = vm.currentOrder.id;
                        updatedProductDTO.mode = "CANCEL-DISCOUNT-ROOM";
                        
                       // console.log(updatedProductDTO);
                        makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                            vm.currentOrder = data.data;
                            alert("Đã hủy miễn phí giờ hát và thiết lập lại giá phòng hát cho đơn hàng này thành công!");
                        },function(error){
                        });
          }
        }

        vm.changeRoom = function(){
                var dataQuery = {
                    storeId: vm.currentAccount.store.id
                };
                // get Products
                Room.query(dataQuery, onSuccess, onError);
                function onSuccess(data, headers) {
                    vm.rooms = data.content;
                    vm.openModalChangeRoom();
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
        }

        vm.openModalChangeRoom = function () {
            if(vm.currentOrder != null){
                $uibModal.open({
                  templateUrl: 'app/entities/order-fast-advance/modal-change-room.html',
                  controller: function ($scope, $uibModalInstance) {


                    $scope.emptyRooms = [];
                    loadEmptyRooms();

                    function loadEmptyRooms(){
                      for(var i=0;i<vm.rooms.length;i++){
                        if(vm.rooms[i].orderStatus != 'INPROGRESS'){
                          $scope.emptyRooms.push(vm.rooms[i]);
                        }
                      }
                    }

                    $scope.selectRoom = function(room){
                        var updatedProductDTO = {};
                        updatedProductDTO.billId = vm.currentOrder.id;
                        updatedProductDTO.mode = "CHANGE-ROOM";
                        updatedProductDTO.targetRoomId = Number(room.id);
                        
                       // console.log(updatedProductDTO);
                        makePutCall('api/bills/update-product',updatedProductDTO).then(function(data){
                            vm.currentOrder = data.data;
                            vm.loadAllRoom();;
                            $uibModalInstance.close(true);
                        },function(error){
                        });
                    }

                    $scope.cancel = function () {
                      $uibModalInstance.dismiss('cancel');
                    };
                  }
                })
                 }
        };

        
       vm.printBill = function(billId){
        $state.go('bill-print-preview',{id:billId});
       }
        vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }

        // copy function
        function shallowCopy( original )
        {
            if(original == null || original == 'undefined'){
                return null;
            }
            // First create an empty object with
            // same prototype of our original source
            var clone = Object.create( Object.getPrototypeOf( original ) ) ;

            var i , keys = Object.getOwnPropertyNames( original ) ;

            for ( i = 0 ; i < keys.length ; i ++ )
            {
                // copy each property into the clone
                Object.defineProperty( clone , keys[ i ] ,
                    Object.getOwnPropertyDescriptor( original , keys[ i ] )
                ) ;
            }

            return clone ;
        }




    }
})();
