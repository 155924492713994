(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['PosSetting','$scope', 'Principal', 'LoginService', '$state','toastr', 
                                '$rootScope','$timeout','Auth','JhiLanguageService','$localStorage',
                                '$window', 'ProfileService', 'AlertService','$http', '$q','$stateParams'];

    function HomeController (PosSetting,$scope, Principal, LoginService, $state, toastr, 
                            $rootScope, $timeout, Auth, JhiLanguageService, $localStorage, $window, ProfileService, AlertService,$http, $q, $stateParams) {
        var vm = this;
        $timeout(function (){angular.element('#username').focus();});
       
        if (!angular.isUndefined($localStorage.isFirstApp)){
            //delete $localStorage.isFirstApp;
            $window.location.reload();
        }

       

        $rootScope.isHeader = true;
        vm.apiUrl = $localStorage.API_URL;
        var API_URL = $localStorage.API_URL;
        
        vm.account = null;
        vm.isAuthenticated = null;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        $rootScope.$broadcast('isHideNav');
        getAccount();
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
       
       
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

     

        function login (event) {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: true
            }).then(function () {
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    console.log('Stage 0');
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }else{
                   $state.go('navigate');  
                  
                }
            }).catch(function (e) {
               // console.log(e);
                vm.authenticationError = true;
            });
        }
        
    }
})();
