(function() {
    'use strict';

    angular
        .module('xposApp')
        .factory('RegisterAppSrevice', RegisterAppSrevice);

    RegisterAppSrevice.$inject = ['$http', '$localStorage', '$sessionStorage', '$q','$state'];

    function RegisterAppSrevice ($http, $localStorage, $sessionStorage, $q, $state) {
        var service = {
            register: register,
            initialize:initialize
        };

        return service;


        function register (register) {
            return $http.post("http://"+register.localservice+'/api/registerappsimple', register)
                        .then(registerSuccess);

            function registerSuccess (res, status, headers) {
                return res.data;
            }
        }
        function validateApp (url) {
                console.log("validateApp: register.service.js.28" );
                if (url=='/register') {
                    return;
                } else {

                // if (url=='/'){
                //     if (angular.isUndefined($localStorage.validateKey)) $state.go('register');
                //     return;
                // }
                 var validate = {
                    customerCode :$localStorage.customerCode,
                    productCode:$localStorage.productCode,
                    fullName:$localStorage.fullName,
                    email:$localStorage.email,
                    license:$localStorage.validateKey,
                    hardwareId:"MACTEST"
                };

                try {
                    /*
                    var macaddress = require('node-macaddress');
                    macaddress.one(function (err, mac) {
                        validate.hardwareId = mac;
                        var res = $http.post($localStorage.API_URL+'api/validateappsimple', validate)
                        res.success(function(data, status, headers, config) {
                            if (!angular.isUndefined($localStorage.validateKey)) {
                                if ((data.keyValidate == false || data.dayExp < 0)){
                                    if (url!='/register'){
                                        $state.go('registererror');
                                         }
                                    } else {
                                        if (url =='/expried')
                                        {
                                            $state.go('home');
                                        }
                                    }
                            }
                        });
                        res.error(function(data, status, headers, config) {

                            if (url!='/register'){
                                if (angular.isUndefined($localStorage.validateKey)){
                                     $state.go('register');
                                }else {
                                    $state.go('registererror');
                                }
                            }
                        });
                    });
                    */

                    var serialNumber = require('serial-number');

                    serialNumber(function (err, value) {
                        validate.hardwareId = value;
                        var res = $http.post($localStorage.API_URL+'api/validateappsimple', validate)
                        res.success(function(data, status, headers, config) {
                            if (!angular.isUndefined($localStorage.validateKey)) {
                                if ((data.keyValidate == false || data.dayExp < 0)){
                                    if (url!='/register'){
                                        $state.go('registererror');
                                         }
                                    } else {
                                        if (url =='/expried')
                                        {
                                            $state.go('home');
                                        }
                                    }
                            }
                        });
                        res.error(function(data, status, headers, config) {

                            if (url!='/register'){
                                if (angular.isUndefined($localStorage.validateKey)){
                                     $state.go('register');
                                }else {
                                    $state.go('registererror');
                                }
                            }
                        });
                    });

                } catch(e){
                    // if you not electron
                    validate.hardwareId = "MACTEST";
                    var res = $http.post($localStorage.API_URL+'api/validateappsimple', validate)
                    res.success(function(data, status, headers, config) {
			            if (!angular.isUndefined($localStorage.validateKey)) {
                             if ((data.keyValidate == false || data.dayExp < 0)){
                                    $state.go('registererror');
                                } else {
                                    if (url =='/expried')
                                    {
                                        $state.go('home');
                                    }
                                }
                        }
		            });
                    res.error(function(data, status, headers, config) {
                         $state.go('registererror');
                    });
                }
            }
        }
        function initialize(url){
             validateApp(url);
        //     if (!angular.isUndefined($localStorage.validateKey)) {
        //         validateApp().then(function(data){

        //             if ((data.keyValidate == false || data.dayExp < 0)){
        //                 $state.go('registererror');
        //             } else {
        //                 console.log(url);
        //                 if (url =='/expried')
        //                 {
        //                     $state.go('home');
        //                 }
        //             }
        //         }).catch(function (response) {

        //         });
        //     }
        }
    }
})();
