(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('StoreDeleteController',StoreDeleteController);

    StoreDeleteController.$inject = ['$localStorage','$q','$http','$uibModalInstance', 'entity', 'Store'];

    function StoreDeleteController($localStorage, $q, $http, $uibModalInstance, entity, Store) {
        var vm = this;
        vm.apiUrl = $localStorage.API_URL;
        vm.product = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

         function makePutCall(url,data) {
            return $http.put(vm.apiUrl+url,data)
                .then(function (response) {
                  return response;
                }, function (response) {
                    return $q.reject(response.data);
                });
        };
        function confirmDelete (id) {
            makePutCall('api/stores/deactive',id).then(function(data){
                     $uibModalInstance.close(true);
            },function(error){
            });
            
        }
    }
})();
