(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderPosDeleteController',OrderPosDeleteController);

    OrderPosDeleteController.$inject = ['$uibModalInstance', 'entity', 'OrderPos'];

    function OrderPosDeleteController($uibModalInstance, entity, OrderPos) {
        var vm = this;

        vm.orderPos = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OrderPos.delete({id: id},
                function () {
                    
                    $uibModalInstance.close(true);
                });
        }
    }
})();
