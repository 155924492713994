(function() {
	'use strict';

	angular.module('xposApp').controller('ConfigDialogController',
			ConfigDialogController);

	ConfigDialogController.$inject = ['$http','Principal', '$timeout', '$scope', '$stateParams','$state','entity', 'Config', 'Upload', '$localStorage'];

	function ConfigDialogController($http, Principal, $timeout, $scope, $stateParams,$state,entity, Config, Upload, $localStorage) {
		var vm = this;
		vm.config = entity;
		//console.log(vm.Config);
		vm.apiUrl = $localStorage.API_URL;
		vm.save = save;
		$timeout(function() {
			angular.element('.form-group:eq(0)>input').focus();
		});


		var makeRestCall   = function (url) {
            return $http.get(vm.apiUrl +url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };
        makeRestCall('api/configs/system-string').then(function(data){
              vm.systemConfigs = (data.data);
            },function(error){
        });

		getAccount();
        function getAccount(callback) {
            Principal.identity().then(function (account) {
                vm.account = account;
                // $rootScope.flag = vm.account.langKey;
                vm.isAuthenticated = Principal.isAuthenticated;
                 if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
                 }

                 //console.log(vm.account);
                 if(callback != null){
                 	callback();
                 }
            });
        }

		function save() {
			getAccount(_save);

			function _save(){
				vm.isSaving = true;
				if (vm.config.id !== null) {
					Config.update(vm.config, onSaveSuccess, onSaveError);
				} else {
					vm.config.store = vm.account.store;
					Config.save(vm.config, onSaveSuccess, onSaveError);
				}
			}
			
			function onSaveSuccess(result) {
				$state.go('config');
				vm.isSaving = false;
			}

			function onSaveError(result) {
				
				if(result.status == 400){
					alert("Không được phép tạo thiết lập mới trùng tên với thiết lập cũ");
				}
				vm.isSaving = false;
			}
		}

		


		vm.back = function(){
			$state.go('config');
		}

		
	}
})();
