(function () {
    'use strict';

    angular
            .module('xposApp')
            .controller('LeftMenuController', LeftMenuController);

    LeftMenuController.$inject = ['$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function LeftMenuController($scope, $state, Auth, Principal, ProfileService, LoginService) {
        var vm = this;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        // ProfileService.getProfileInfo().then(function (response) {
        //     vm.inProduction = response.inProduction;
        //     vm.swaggerEnabled = response.swaggerEnabled;
        // });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            vm.account = Principal.identity().$$state.value;
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            //vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            //vm.isNavbarCollapsed = true;
        }

        $scope.$on('$viewContentLoaded', function () {
//            jQuery.ajax({
//                url: "/app/custom.js",
//                dataType: 'script',
//                success: function () {},
//                async: true
//            });
        });

    }
})();
