(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('ConfigController', ConfigController);

    ConfigController.$inject = ['Auth','Principal','$q','Upload','$timeout','$rootScope','$stateParams','$state', 'Config', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$localStorage','$http'];

    function ConfigController(Auth, Principal, $q,Upload, $timeout, $rootScope, $stateParams, $state, Config, ParseLinks, AlertService, paginationConstants, pagingParams, $localStorage, $http) {

        var vm = this;
        vm.$state = $state;
        vm.apiUrl = $localStorage.API_URL;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 100;
        vm.cleanTransition = cleanTransition;
        vm.search = {};
       

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
            loadAll();
           
        });


        
        function loadAll () {
        	var dataQuery = {
                   storeId : vm.currentAccount.store.id
                };
               
                // get Configs
                Config.query(dataQuery, onSuccessGetConfig, onErrorGetConfig);
                function sort() {
                    var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                    return result;
                }
                function onSuccessGetConfig(data, headers) {
                    vm.totalItems =data.totalElements;
                    vm.queryCount = data.totalElements;
                    vm.configs = data.content;
                    vm.page = pagingParams.page;
                }
                function onErrorGetConfig(error) {
                    AlertService.error(error.data.message);
                }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var query = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }
            
            $state.transitionTo($state.$current, query);
        }
        
        function cleanTransition(){

        	transition();
        }


        vm.selectConfig = function(config){
            vm.currentConfigId = config.id;
        }

        vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }

    }
})();
