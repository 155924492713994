(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderPosCreateNewController', OrderPosCreateNewController);

    OrderPosCreateNewController.$inject = ['$state', 'TablePos', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function OrderPosCreateNewController($state, TablePos, ParseLinks, AlertService, paginationConstants, pagingParams) {

    	var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.cleanTransition = cleanTransition;
        vm.itemsPerPage = 10;
        vm.search = {};
        vm.clickMakeOrder = clickMakeOrder;
        loadAll();

        function loadAll () {
            var dataQuery = {
            	pageNo: pagingParams.page,
                pageSize: vm.itemsPerPage,
                sortBy: sort()
            };
            dataQuery.requestStatusForOrder = true;
            
            if (!angular.isUndefined(pagingParams.tableNo)){
                dataQuery.tableNo = pagingParams.tableNo;
                vm.search.tableNo = pagingParams.tableNo;
            }
            if (!angular.isUndefined(pagingParams.tableZone)){
                dataQuery.tableZone = pagingParams.tableZone;
                vm.search.tableZone = pagingParams.tableZone;
            }
           
            TablePos.query(dataQuery, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                // if (vm.predicate !== 'id') {
                //     result.push('id');
                // }
                return result;
            }
            function onSuccess(data, headers) {
                vm.totalItems =data.totalElements;
                vm.queryCount = data.totalElements;
                vm.tablePos = data.content;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var query = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                tableNo: vm.search.tableNo,
                tableZone: vm.search.tableZone
            }
            
            $state.transitionTo($state.$current, query);
        }
        
        function cleanTransition(){
        	vm.search.tableNo = '';
        	transition();
        }
        
        function clickMakeOrder(tableID){
        	$state.go('select-meal', {
        		table: tableID //get tableID from row
        	});
        }
    }
})();
