(function() {
    'use strict';

    angular
        .module('xposApp')
        .directive('mAppLoading', mAppLoading);
    mAppLoading.$inject = ['$animate'];
     function mAppLoading($animate) {
        return({
                    link: link,
                    restrict: "C"
                }); 
        function link( scope, element, attributes ) {
            $animate.leave( element.children().eq( 1 ) ).then(
                function cleanupAfterAnimation() {
                    element.remove();
                    scope = element = attributes = null;
                }
            );
        }
    }
})();


