(function() {
    'use strict';
    angular
        .module('xposApp')
        .factory('Stockin', Stockin);

    Stockin.$inject = ['$resource', 'DateUtils', '$localStorage'];

    function Stockin ($resource, DateUtils, $localStorage) {
        var resourceUrl =  $localStorage.API_URL+'api/stockins/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
