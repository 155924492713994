(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('BillMatxaDetailController', BillMatxaDetailController);

    BillMatxaDetailController.$inject = ['$timeout','$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BillMatxa', 'Customer'];

    function BillMatxaDetailController($timeout, $scope, $rootScope, $stateParams, previousState, entity, BillMatxa, Customer) {
        var vm = this;

        vm.billMatxa = entity;
        //console.log(vm.billMatxa);
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('xposApp:billMatxaUpdate', function(event, result) {
            vm.billMatxa = result;

        });
        $scope.$on('$destroy', unsubscribe);
/*
         vm.reFocus =reFocus;
        function reFocus(){
            angular.element('#input_focus').focus();
        }
        $timeout(function (){
            vm.reFocus();
        });
        */
    }
})();
