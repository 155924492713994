(function() {
	'use strict';

	angular.module('xposApp').controller('OrderPosSelectCustomerController',
			OrderPosSelectCustomerController);

	OrderPosSelectCustomerController.$inject = [ '$timeout', '$rootScope', '$scope',
			'$stateParams', '$uibModalInstance', 'AlertService', 'entity','Customer' ,'JhiLanguageService'];

	function OrderPosSelectCustomerController($timeout, $rootScope,$scope,
			$stateParams, $uibModalInstance, AlertService, entity, Customer, JhiLanguageService) {
		var vm = this;
		vm.currentOrder = entity;
		vm.clear = clear;
		vm.loadAllCustomer = loadAllCustomer;
		vm.selectCustomer = selectCustomer;
		initKeyboard();
		loadAllCustomer();

		$scope.$on('languageChange', function(data) {
            initKeyboard();
        });
		function initKeyboard(){
            
            vm.t04Config = {layout: JhiLanguageService.getKeyboard(), kt: 'US International', showKbSelect: true,
             numberPad: true, showVersion: true, usePreview: false, autoAccept : true, reposition : true,
             position : {
                  // null (attach to input/textarea) or a jQuery object (attach elsewhere)
                  of : $(window),
                  my : 'center bottom',
                  at : 'center bottom',
                  // at2 is used when "usePreview" is false (centers keyboard at the bottom
                  // of the input/textarea)
                  at2: 'center bottom',
                  collision: 'flipfit flipfit'
                },
                visible: function(e, keyboard, el) {
                  keyboard.$preview[0].select();
                },
                change: function(event, keyboard, el) {
                    vm.search = (keyboard.$preview.val());
                    loadAllCustomer();
                  }
         }
        }

		// ***
		// Load all Customers resource
		function loadAllCustomer() {
			var dataQueryLoadAllCustomer = {
				pageNo : null,
				pageSize : null,
				sortBy : sortLoadAllCustomer()
			};
			if(vm.search != null && vm.search != ''){
				//console.log(vm.search);
				dataQueryLoadAllCustomer.searchOr =true;
				dataQueryLoadAllCustomer.customerName = vm.search;
				dataQueryLoadAllCustomer.customerAddress = vm.search;
				dataQueryLoadAllCustomer.customerTaxCode = vm.search;
				dataQueryLoadAllCustomer.customerPhone = vm.search;
				dataQueryLoadAllCustomer.customerMail = vm.search;
				dataQueryLoadAllCustomer.street = vm.search;
				dataQueryLoadAllCustomer.customerPostCode = vm.search;
			}
			

			Customer.query(dataQueryLoadAllCustomer, onSuccessLoadAllCustomer,
					onErrorLoadAllCustomer);
			function sortLoadAllCustomer() {
				var result = [];
				result.push('customerName:asc');
				return result;
			}
			function onSuccessLoadAllCustomer(data, headers) {
				vm.customers = data.content;
				// console.log(vm.customers);
			}
			function onErrorLoadAllCustomer(error) {
				AlertService.error(error.data.message);
			}
		}

		function selectCustomer(customer){
			vm.currentOrder.customerDTO = customer;
			$rootScope.$broadcast('updateCurrentOrder', vm.currentOrder);
            $uibModalInstance.dismiss('cancel');
		}
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

	}
})();
