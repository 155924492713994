(function() {
    'use strict';

    angular
        .module('xposApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('billMatxa', {
            parent: 'entity',
            url: '/billMatxa?page&sort&search&tablePos&waiter&customer&products&searchFrom&searchTo',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.billMatxa.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/billMatxa/billMatxas.html',
                    controller: 'BillMatxaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        searchFrom: $stateParams.searchFrom,
                        searchTo: $stateParams.searchTo,
                        tablePos: $stateParams.tablePos,
                        waiter: $stateParams.waiter,
                        customer: $stateParams.customer,
                        products: $stateParams.products
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('billMatxa-edit', {
            parent: 'entity',
            url: '/billMatxa-edit?page&sort&search&tablePos&waiter&customer&products&searchFrom&searchTo',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.billMatxa.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/billMatxa/billMatxa-edit.html',
                    controller: 'BillMatxaEditController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        searchFrom: $stateParams.searchFrom,
                        searchTo: $stateParams.searchTo,
                        tablePos: $stateParams.tablePos,
                        waiter: $stateParams.waiter,
                        customer: $stateParams.customer,
                        products: $stateParams.products
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('billMatxa-detail', {
            parent: 'billMatxa',
            url: '/billMatxa/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.billMatxa.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/billMatxa/billMatxa-detail.html',
                    controller: 'BillMatxaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'BillMatxa', function($stateParams, BillMatxa) {
                    return BillMatxa.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'billMatxa',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('billMatxa-print-preview', {
            parent: 'billMatxa',
            url: '/billMatxa-print-preview/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.billMatxa.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/billMatxa/billMatxa-print-preview.html',
                    controller: 'BillMatxaPrintPreviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'BillMatxa', function($stateParams, BillMatxa) {
                    return BillMatxa.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'billMatxa',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        
        
    }

})();
