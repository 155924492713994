(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('BillPrintPreviewController', BillPrintPreviewController);

    BillPrintPreviewController.$inject = ['entity','Principal','$timeout','$scope','$rootScope','$state', 'Bill', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','PosSetting','$http', '$q', '$localStorage','DateUtils'];

    function BillPrintPreviewController(entity, Principal, $timeout, $scope, $rootScope, $state, Bill, ParseLinks, AlertService, paginationConstants, pagingParams, PosSetting,$http, $q, $localStorage,DateUtils) {

    	 var vm = this;
         vm.apiUrl = $localStorage.API_URL;
         vm.bill = entity;

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
            vm.store = vm.bill.store;
             //console.log(vm.store);
        });



     
       

        vm.back = function(){
             $state.go('order-karaoke');
        }

        vm.print = function(){
            window.print();
            $state.go('order-karaoke');
        }

    }
})();
