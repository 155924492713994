(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('PosSettingController', PosSettingController);

    PosSettingController.$inject = ['PosSetting'];

    function PosSettingController(PosSetting) {

        var vm = this;

        vm.posSettings = [];

        loadAll();

        function loadAll() {
            PosSetting.query(function(result) {
                vm.posSettings = result;
                vm.searchQuery = null;
            });
        }
    }
})();
