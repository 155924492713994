(function() {
	'use strict';

	angular.module('xposApp').controller('StoreDialogController',
			StoreDialogController);

	StoreDialogController.$inject = [ '$timeout', '$scope', '$stateParams','$state','entity', 'Store', 'Upload', '$localStorage'];

	function StoreDialogController($timeout, $scope, $stateParams,$state,entity, Store, Upload, $localStorage) {
		var vm = this;
		vm.store = entity;
		//console.log(vm.store);
		vm.apiUrl = $localStorage.API_URL;
		vm.save = save;
		$timeout(function() {
			angular.element('.form-group:eq(0)>input').focus();
		});

		function save() {
			vm.isSaving = true;
			if (vm.store.id !== null) {
				Store.update(vm.store, onSaveSuccess, onSaveError);
			} else {
				Store.save(vm.store, onSaveSuccess, onSaveError);
			}
			
		}

		function onSaveSuccess(result) {
			$state.go('store');
			vm.isSaving = false;
		}

		function onSaveError(result) {
			
			if(result.status == 400){
				alert("Không được phép tạo cơ sở mới trùng tên với cơ sở cũ");
			}
			vm.isSaving = false;
		}


		vm.back = function(){
			$state.go('store');
		}

		vm.uploadImage = uploadImage;
        function uploadImage(){
            var file = vm.uploadedImage;
            var resourceUrl =  $localStorage.API_URL+'api/upload_product_image';
            //console.log(resourceUrl);
            if(file != null){
            	console.log(resourceUrl);
                Upload.upload({
                url: resourceUrl,
                data: {file: file}
                }).then(function (data, header, status) {
                	vm.store.storeImage = data.data.hash;
                    //vm.uploadedImage = data.data.hash;
                   // console.log(data.data.hash);
                    alert("OK");
                    
                }, function (resp) {
                    alert("Lỗi upload ảnh");
                });
            }
             
        }
	}
})();
