(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('ProductController', ProductController);

    ProductController.$inject = ['Store','Auth','Principal','$q','Upload','$timeout','$rootScope','$stateParams','$state', 'Product', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$localStorage','$http'];

    function ProductController(Store, Auth, Principal, $q,Upload, $timeout, $rootScope, $stateParams, $state, Product, ParseLinks, AlertService, paginationConstants, pagingParams, $localStorage, $http) {

        var vm = this;
        vm.$state = $state;
        vm.apiUrl = $localStorage.API_URL;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 100;
        vm.cleanTransition = cleanTransition;
        vm.search = {};
        vm.loadAll = loadAll;

        Principal.identity().then(function(account) {
            vm.account = account;
            if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
            }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
             }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
             }

            vm.filterStore = vm.account.store;
            loadAll();
            loadAllStore();
        });


        
        function loadAll () {
        	var dataQuery = {
                   active: 1
                };
               if(vm.filterStore.id != 1){
                    dataQuery.storeId =  vm.filterStore.id;
                 }
                // get Products
                Product.query(dataQuery, onSuccessGetProduct, onErrorGetProduct);
                
                function onSuccessGetProduct(data, headers) {
                    vm.totalItems =data.totalElements;
                    vm.queryCount = data.totalElements;
                    vm.products = data.content;
                    vm.page = pagingParams.page;
                }
                function onErrorGetProduct(error) {
                    AlertService.error(error.data.message);
                }
        }

        vm.loadAllStore = loadAllStore;
         function loadAllStore(){
                 var dataQuery = {
                 };
                 // get Bills
                 Store.query(dataQuery, onSuccess, onError);
                 
                 function onSuccess(data, headers) {
                     vm.stores = data.content;
                    //console.log(vm.stores);
                 }
                 function onError(error) {
                     AlertService.error(error);
                 }
         }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var query = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }
            
            $state.transitionTo($state.$current, query);
        }
        
        function cleanTransition(){

        	transition();
        }


        vm.selectProduct = function(product){
            vm.currentProductId = product.id;
        }

        vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }

    }
})();
