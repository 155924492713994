(function() {
    'use strict';

    angular
        .module('xposApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            // 'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ngAnimate',
            'moment-picker',
            'toastr',
            'ng-virtual-keyboard',
            'uiSwitch'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', 'keyboardHandler'];

    function run(stateHandler, translationHandler , keyboardHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
        keyboardHandler.initialize();
    }
})();
