(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderPosDialogController', OrderPosDialogController);

    OrderPosDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance','Principal', 'entity', 'OrderPos','User'];

    function OrderPosDialogController ($timeout, $scope, $stateParams, $uibModalInstance,Principal, entity, OrderPos, User) {
        var vm = this;

        vm.account = null;
        vm.waiters = null;
        vm.orderPos = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        getLoginAccount();
       

        function getLoginAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if(vm.orderPos.waiter === null || vm.orderPos.waiter === ''){
                	vm.orderPos.waiter = account.login;
                }
                getAllWaiter();
            });
        }
        
        function getAllWaiter () {
            User.query({
            	// add condition: authorities = ROLE_USER here???
            	//
            }, onSuccessGetAllWaiter, onErrorGetAllWaiter);
        }

        function onSuccessGetAllWaiter(data, headers) {
            vm.waiters = data.filter(function (el) {
            	  return el.authorities.length === 1 &&
            	  el.authorities[0] === 'ROLE_USER';
         });
            
        }

        function onErrorGetAllWaiter(error) {
            AlertService.error(error.data.message);
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.orderPos.id !== null) {
                OrderPos.update(vm.orderPos, onSaveSuccess, onSaveError);
            } else {
                OrderPos.save(vm.orderPos, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('xposApp:orderPosUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startedAt = false;
        vm.datePickerOpenStatus.endAt = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
