(function () {
    'use strict';

    angular
        .module('xposApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES'];

    function JhiLanguageService ($q, $http, $translate, LANGUAGES) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent,
            getKeyboard: getKeyboard
        };
        return service;
        function getAll () {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent () {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

            deferred.resolve(language);

            return deferred.promise;
        }

        function getKeyboard(){
           var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
            if ( language == "en"){
                //return "ms-US-International";
                return "ms-German (IBM)";
            } else if(language == "vi")  {
                //return "ms-Vietnamese";
                return "ms-German (IBM)";
            } else {
                return "ms-German (IBM)";
            }
        }
    }
})();
