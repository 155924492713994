(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('SettingAdminController', SettingAdminController);

    SettingAdminController.$inject = ['$scope', '$filter', 'SettingAdminService', 'ParseLinks',
    '$localStorage', '$window', 'PosSetting','$http', '$q','Principal'];

    function SettingAdminController ($scope, $filter, SettingAdminService, ParseLinks,
                                     $localStorage, $window, PosSetting ,$http, $q,Principal) {
        var vm = this;
        vm.setting = {
            virtualkeyboard : false,
            customerCode: "Test",
            diskSetting:"",
            teamViewerPath:"",
            twoMonitors: false

        };
        vm.hiddenHardiskSetting = true;
        vm.diskSetting = "";
        vm.diskObject ;
        vm.customerCodeObject;
        vm.showSuperSettingContent = false;

        initSetting ();

        function initSetting() {
            if (!angular.isUndefined(vm.setting)){
                vm.setting = $localStorage.setting;

                if(vm.setting.twoMonitors == "yes"){
                    vm.setting.twoMonitors = true;
                }
                if(vm.setting.twoMonitors == "no"){
                    vm.setting.twoMonitors = false;
                }

               
            }


            SettingAdminService.getHardisks().then(function(hardisks){
                vm.setting.hardisks = hardisks;
                //console.log(hardisks);
                vm.hiddenHardiskSetting = false;
                if (hardisks.length ==0){
                    vm.hiddenHardiskSetting = true;
                }

            },function(){
                vm.setting.hardisks = [];
                vm.hiddenHardiskSetting = true;
            });



            makeRestCall('api/setting?type=PRINT_DRINK').then(function(data){
                        vm.printDrink = data.data;
                        if(vm.printDrink != null){
                            if(vm.printDrink.setting == '1') vm.printDrink.setting = true;
                            if(vm.printDrink.setting == '0') vm.printDrink.setting = false;
                        }

                    },function(error){
            });

            makeRestCall('api/setting?type=STYLE').then(function(data){
                if(data != null){
                    vm.style = data.data;
                }
             },function(error){
            });

            makeRestCall('api/setting?type=DRINKS_LEFT').then(function(data){
                if(data != null){
                    vm.drinksLeft = data.data;
                        if(vm.drinksLeft != null){
                            if(vm.drinksLeft.setting == '1') vm.drinksLeft.setting = true;
                            if(vm.drinksLeft.setting == '0') vm.drinksLeft.setting = false;
                        }
                }

            },function(error){
            });
            makeRestCall('api/setting?type=SINGLE_ITEM').then(function(data){
                if(data != null){
                    vm.singleItem = data.data;
                        if(vm.singleItem != null){
                            if(vm.singleItem.setting == '1') vm.singleItem.setting = true;
                            if(vm.singleItem.setting == '0') vm.singleItem.setting = false;
                        }
                }
            },function(error){
            });

            makeRestCall('api/setting?type=SINGLE_KITCHEN').then(function(data){
                if(data != null){
                    vm.printSingleItemKitchen = data.data;
                        if(vm.singleItem != null){
                            if(vm.printSingleItemKitchen.setting == '1') vm.printSingleItemKitchen.setting = true;
                            if(vm.printSingleItemKitchen.setting == '0') vm.printSingleItemKitchen.setting = false;
                        }
                }
            },function(error){
            });

            makeRestCall('api/setting?type=SEARCH_CUSTOMER_BY_CODE').then(function(data){
                if(data != null){
                    vm.searchCustomerByCode = data.data;
                        if(vm.singleItem != null){
                            if(vm.searchCustomerByCode.setting == '1') vm.searchCustomerByCode.setting = true;
                            if(vm.searchCustomerByCode.setting == '0') vm.searchCustomerByCode.setting = false;
                        }
                }
            },function(error){
            });

            makeRestCall('api/setting?type=PRINT_ARTICAL_NUMBER_IN_KITCHEN').then(function(data){
                if(data != null){
                    vm.printArticalNumberInKitchen = data.data;
                        if(vm.singleItem != null){
                            if(vm.printArticalNumberInKitchen.setting == '1') vm.printArticalNumberInKitchen.setting = true;
                            if(vm.printArticalNumberInKitchen.setting == '0') vm.printArticalNumberInKitchen.setting = false;
                        }
                }
            },function(error){
            });

            makeRestCall('api/setting?type=FONT_SIZE_IN_KITCHEN').then(function(data){
                if(data != null){
                    vm.fontSizeKitchen = data.data;

                }
            },function(error){
            });


            vm.saveFontSize = function(){
                var input = Number(vm.fontSizeKitchen.setting);
                if(input <=0 || input > 50){
                    vm.fontSizeKitchen.setting = '13';
                }
                makePutCall('api/pos-settings',vm.fontSizeKitchen).then(function(data){
                     vm.fontSizeKitchen = data.data;
                     alert("OK");
                },function(error){
                });
            }

            makeRestCall('api/setting?type=FAST_LOGIN').then(function(data){
                if(data != null){
                    vm.fastLogin = data.data;

                }
            },function(error){
            });
            

            vm.saveFastLogin = function(){
                var input = Number(vm.fastLogin.setting);
                if(input <=0 || input > 20){
                    vm.fastLogin.setting = '3';
                }
                
                makePutCall('api/pos-settings',vm.fastLogin).then(function(data){
                     vm.fastLogin = data.data;
                     alert("OK");
                },function(error){
                });
            }

            makeRestCall('api/setting?type=COMMENTS_TEMPLATE').then(function(data){
                if(data != null){
                    vm.commentsTemplate = data.data;

                }
            },function(error){
            });
            

            vm.saveCommentTemplate = function(){
                makePutCall('api/pos-settings',vm.commentsTemplate).then(function(data){
                     vm.commentsTemplate = data.data;
                     alert("OK");
                },function(error){
                });
            }

            makeRestCall('api/setting?type=TIEN_VON').then(function(data){
                if(data != null){
                    vm.TIEN_VON = data.data;

                }
            },function(error){
            });
            

            vm.saveTienVon = function(){
                makePutCall('api/pos-settings',vm.TIEN_VON).then(function(data){
                     vm.TIEN_VON = data.data;
                     alert("OK");
                },function(error){
                });
            }

            makeRestCall('api/setting?type=SMARTSTORE').then(function(data){
                        vm.diskObject = data.data;
                        if(vm.diskObject  != null){
                        vm.setting.diskSetting = vm.diskObject.setting;
                        }

                    },function(error){
                });

            makeRestCall('api/setting?type=CUSTOMERCODE').then(function(data){

                    vm.customerCodeObject = data.data;
                    if(vm.customerCodeObject != null){
                        vm.setting.customerCode = vm.customerCodeObject.setting;
                    }
                    },function(error){
                });

             makeRestCall('api/setting?type=TEAMVIEWER_PATH').then(function(data){
                        if(data.data  != null){
                            vm.settingTeamviewer = data.data;
                            vm.setting.teamViewerPath = data.data.setting;
                        }

                    },function(error){
            });

            makeRestCall('api/setting?type=SHOW_IMAGE').then(function(data){
                        vm.showImage = data.data;
                        if(vm.showImage != null){
                            if(vm.showImage.setting == '1') vm.showImage.setting = true;
                            if(vm.showImage.setting == '0') vm.showImage.setting = false;
                        }

                    },function(error){
            });

            initSuper();

        }

        function initSuper(){
            makeRestCall('api/setting?type=STEPSMART').then(function(data){
                        if(data.data  != null){
                            vm.smartStep = data.data;
                        }

                    },function(error){
            });

            makeRestCall('api/setting?type=STEPSMARTCHANGE').then(function(data){
                        if(data.data  != null){
                            vm.smartChange = data.data;
                        }

                    },function(error){
            });

            makeRestCall('api/setting?type=MIN_LIMIT').then(function(data){
                        if(data.data  != null){
                            vm.minLimit = data.data;
                        }

                    },function(error){
            });

            makeRestCall('api/setting?type=MAX_LIMIT').then(function(data){
                        if(data.data  != null){
                            vm.maxLimit = data.data;
                        }

                    },function(error){
            });

            makeRestCall('api/setting?type=PASS_SETTING_SUPER_USER').then(function(data){
                    if(data.data  != null){
                            vm.smartStepPassword = data.data;
                    }
                    },function(error){
                });
        }

        Principal.identity().then(function(account) {
                    vm.currentAccount = account;
                    vm.currentRole = 'ROLE_USER';
                          for (var j = 0; j < vm.currentAccount.authorities.length; j++) {
                            if(vm.currentAccount.authorities[j] == 'ROLE_ADMIN'){
                                vm.currentRole = 'ROLE_ADMIN';
                                break;
                            }else
                            if(vm.currentAccount.authorities[j] == 'ROLE_SUPER_ADMIN'){
                                vm.currentRole = 'ROLE_SUPER_ADMIN';
                                break;
                            }
                            else
                            if(vm.currentAccount.authorities[j] == 'ROLE_SUPER_USER'){
                                vm.currentRole = 'ROLE_SUPER_USER';
                                break;
                            }
                          }
        });

        vm.t003Config = {layout: 'custom',
              customLayout: {
                'default': [
                  '{accept} {b}',
                  '6 7 8 9',
                  '2 3 4 5',
                  '000 00 0 1'
                ]
              },
              display: {
                'accept': 'OK'
              },
              kt: 'US International', showKbSelect: true,
             numberPad: true, showVersion: true, usePreview: false, autoAccept : true, reposition : true,
             position : {
                  // null (attach to input/textarea) or a jQuery object (attach elsewhere)
                  of : $(window),
                  my : 'center bottom',
                  at : 'center bottom',
                  // at2 is used when "usePreview" is false (centers keyboard at the bottom
                  // of the input/textarea)
                  at2: 'center bottom',
                  collision: 'flipfit flipfit'
                },
                visible: function(e, keyboard, el) {
                  keyboard.$preview[0].select();
                }

         }


        vm.saveSetting = function (){
             if (angular.isUndefined($localStorage.setting)) $localStorage.setting = vm.setting;
             $localStorage.setting.virtualkeyboard = vm.setting.virtualkeyboard;
             //$localStorage.setting = vm.setting;
             localStorage["jhi-isFirstApp"] = false;
             $window.location.reload();
        }

        vm.cancelSuperSetting = function(){
            initSuper();
        }
        vm.saveSuperSetting = function(){
            makePutCall('api/pos-settings',vm.smartStep).then(function(data){
                    makePutCall('api/pos-settings',vm.smartChange).then(function(data){
                            makePutCall('api/pos-settings',vm.minLimit).then(function(data){
                                    makePutCall('api/pos-settings',vm.maxLimit).then(function(data){
                                            initSuper();
                                            alert("OK");
                                        },function(error){
                                    });
                                },function(error){
                            });
                        },function(error){
                    });
                },function(error){
                });

        }

        vm.tryPassword = function(){
            if(vm.smartStep_Password == vm.smartStepPassword.setting){
                vm.showSuperSettingContent = true;
            }
        }



        vm.changePrintDrink = function(){
            if(vm.printDrink != null){
                        if(vm.printDrink.setting == true) vm.printDrink.setting = '1';
                        if(vm.printDrink.setting == false) vm.printDrink.setting = '0';
            }
            makePutCall('api/pos-settings',vm.printDrink).then(function(data){
                         vm.printDrink = data.data;
                        if(vm.printDrink != null){
                            if(vm.printDrink.setting == '1') vm.printDrink.setting = true;
                            if(vm.printDrink.setting == '0') vm.printDrink.setting = false;
                        }
                    },function(error){
                    });
        }

        vm.changeShowImage = function(){
            if(vm.showImage != null){
                        if(vm.showImage.setting == true) vm.showImage.setting = '1';
                        if(vm.showImage.setting == false) vm.showImage.setting = '0';
            }
            makePutCall('api/pos-settings',vm.showImage).then(function(data){
                        vm.showImage = data.data;
                        if(vm.showImage != null){
                            if(vm.showImage.setting == '1') vm.showImage.setting = true;
                            if(vm.showImage.setting == '0') vm.showImage.setting = false;
                        }
                    },function(error){
                    });
        }

        vm.changeDrinksPosition = function(){
            if(vm.drinksLeft != null){
                        if(vm.drinksLeft.setting == true) vm.drinksLeft.setting = '1';
                        if(vm.drinksLeft.setting == false) vm.drinksLeft.setting = '0';
            }
            makePutCall('api/pos-settings',vm.drinksLeft).then(function(data){
                         vm.drinksLeft = data.data;
                        if(vm.drinksLeft != null){
                            if(vm.drinksLeft.setting == '1') vm.drinksLeft.setting = true;
                            if(vm.drinksLeft.setting == '0') vm.drinksLeft.setting = false;
                        }
                    },function(error){
                    });
        }

        vm.changeSingleItem = function(){
            if(vm.singleItem != null){
                        if(vm.singleItem.setting == true) vm.singleItem.setting = '1';
                        if(vm.singleItem.setting == false) vm.singleItem.setting = '0';
            }
            makePutCall('api/pos-settings',vm.singleItem).then(function(data){
                         vm.singleItem = data.data;
                        if(vm.singleItem != null){
                            if(vm.singleItem.setting == '1') vm.singleItem.setting = true;
                            if(vm.singleItem.setting == '0') vm.singleItem.setting = false;
                        }
                    },function(error){
                    });
        }

        vm.changePrintSingleItemKitchen = function(){
            if(vm.printSingleItemKitchen != null){
                        if(vm.printSingleItemKitchen.setting == true) vm.printSingleItemKitchen.setting = '1';
                        if(vm.printSingleItemKitchen.setting == false) vm.printSingleItemKitchen.setting = '0';
            }
            makePutCall('api/pos-settings',vm.printSingleItemKitchen).then(function(data){
                         vm.printSingleItemKitchen = data.data;
                        if(vm.printSingleItemKitchen != null){
                            if(vm.printSingleItemKitchen.setting == '1') vm.printSingleItemKitchen.setting = true;
                            if(vm.printSingleItemKitchen.setting == '0') vm.printSingleItemKitchen.setting = false;
                        }
                    },function(error){
                    });
        }

        vm.changeSearchCustomerByCode = function(){
            if(vm.searchCustomerByCode != null){
                        if(vm.searchCustomerByCode.setting == true) vm.searchCustomerByCode.setting = '1';
                        if(vm.searchCustomerByCode.setting == false) vm.searchCustomerByCode.setting = '0';
            }
            makePutCall('api/pos-settings',vm.searchCustomerByCode).then(function(data){
                        vm.searchCustomerByCode = data.data;
                        if(vm.searchCustomerByCode != null){
                            if(vm.searchCustomerByCode.setting == '1') vm.searchCustomerByCode.setting = true;
                            if(vm.searchCustomerByCode.setting == '0') vm.searchCustomerByCode.setting = false;
                        }
                    },function(error){
                    });
        }

        vm.changePrintArticalNumberInKitchen = function(){
            if(vm.printArticalNumberInKitchen != null){
                        if(vm.printArticalNumberInKitchen.setting == true) vm.printArticalNumberInKitchen.setting = '1';
                        if(vm.printArticalNumberInKitchen.setting == false) vm.printArticalNumberInKitchen.setting = '0';
            }
            makePutCall('api/pos-settings',vm.printArticalNumberInKitchen).then(function(data){
                        vm.printArticalNumberInKitchen = data.data;
                        if(vm.printArticalNumberInKitchen != null){
                            if(vm.printArticalNumberInKitchen.setting == '1') vm.printArticalNumberInKitchen.setting = true;
                            if(vm.printArticalNumberInKitchen.setting == '0') vm.printArticalNumberInKitchen.setting = false;
                        }
                    },function(error){
                    });
        }
        function makeRestCall(url) {

            return $http.get($localStorage.API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

        function makePutCall(url,data) {

            return $http.put($localStorage.API_URL+url,data)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };


        vm.selectDisk = function(disk){
            if (angular.isUndefined(vm.diskObject) || vm.diskObject == null){
                 vm.diskObject = {};
                 vm.diskObject.type = "SMARTSTORE";
                 vm.diskObject.setting = disk.diskName;
                 PosSetting.save(vm.diskObject);
            } else {
                vm.diskObject.setting = disk.diskName;
                PosSetting.update(vm.diskObject,function(result){
                    vm.setting.diskSetting = disk.diskName;
                });

            }
        }

        vm.startTeamviewer = startTeamviewer;
        function startTeamviewer(){
            if(vm.setting.teamViewerPath != null  && vm.setting.teamViewerPath.length >1){
                if(vm.setting.teamViewerPath != vm.settingTeamviewer.setting){
                    vm.settingTeamviewer.setting = vm.setting.teamViewerPath;
                    // update to backend
                    makePutCall('api/pos-settings',vm.settingTeamviewer).then(function(data){
                        // start teamviewer
                        makeRestCall('api/command/startTeamViewer').then(function(data){
                                    var message = document.getElementById("alertTeamViewerStarted").innerHTML;
                                    alert(message);
                                },function(error){
                        });

                        },function(error){
                    });
                }
                else{
                    // start teamviewer
                    makeRestCall('api/command/startTeamViewer').then(function(data){
                                    var message = document.getElementById("alertTeamViewerStarted").innerHTML;
                                    alert(message);
                                },function(error){
                    });
                }

            }

        }

        vm.change2Monitors = function(){
            if(vm.setting.twoMonitors == true){
                
                $localStorage.setting.twoMonitors = "yes"; 
            }else{
               
                $localStorage.setting.twoMonitors = "no"; 
            }
            
            localStorage["jhi-isFirstApp"] = false;
            $window.location.reload();
        }

    }
})();
