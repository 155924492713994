(function(){
    'use strict';

    angular
        .module('xposApp')
        .factory('storageService', storageService);

    function storageService () {

        return {
            get: function(key) {
                return localStorage.getItem(key);
            },
            set: function(key, data) {
                localStorage.setItem(key, data);
            },
            put: function(key, data){
                localStorage.setItem(key, data);
            }
        };        
    }
})();
