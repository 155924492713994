(function () {
    'use strict';

    angular
        .module('xposApp')
        .factory('Register', Register);

    Register.$inject = ['$resource', '$localStorage'];

    function Register ($resource, $localStorage) {
        return $resource($localStorage.API_URL+'api/register', {}, {});
    }
})();
