(function() {
	'use strict';

	angular.module('xposApp').controller('RoomDialogController',
			RoomDialogController);

	RoomDialogController.$inject = ['Principal', '$timeout', '$scope', '$stateParams','$state','entity', 'Room', 'Upload', '$localStorage'];

	function RoomDialogController(Principal, $timeout, $scope, $stateParams,$state,entity, Room, Upload, $localStorage) {
		var vm = this;
		vm.room = entity;
		if(vm.room != null && vm.room.listPriceByTimeFrameDTO != null){
			vm.listPriceByTimeFrameDTO = vm.room.listPriceByTimeFrameDTO;
		}else{
			vm.listPriceByTimeFrameDTO = [];
		}
		//console.log(vm.room);
		vm.apiUrl = $localStorage.API_URL;
		vm.save = save;
		$timeout(function() {
			angular.element('.form-group:eq(0)>input').focus();
		});

		vm.types = ['VIP','LUXURY'];

		getAccount();
        function getAccount(callback) {
            Principal.identity().then(function (account) {
                vm.account = account;
                // $rootScope.flag = vm.account.langKey;
                vm.isAuthenticated = Principal.isAuthenticated;
                 if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
                 }

                 //console.log(vm.account);
                 if(callback != null){
                 	callback();
                 }
            });
        }

		function save() {
			getAccount(_save);

			function _save(){
				vm.isSaving = true;
				if(checkValidListTimeFrame() == true ){
					if (vm.room.id !== null) {
						//console.log(vm.room);
						vm.room.listPriceByTimeFrameDTO = vm.listPriceByTimeFrameDTO;
						Room.update(vm.room, onSaveSuccess, onSaveError);
					} else {
						vm.room.store = vm.account.store;
						vm.room.listPriceByTimeFrameDTO = vm.listPriceByTimeFrameDTO;
						Room.save(vm.room, onSaveSuccess, onSaveError);
					}
				}else{
					alert("Có vẻ như bạn đã nhập sai giá tiền theo khung giờ. Hãy nhập lại!");
				}
				
			}
			
			function onSaveSuccess(result) {
				$state.go('room');
				vm.isSaving = false;
			}

			function onSaveError(result) {
				
				if(result.status == 400){
					alert("Không được phép tạo phòng mới trùng tên với phòng cũ");
				}
				vm.isSaving = false;
			}
		}

		
		vm.addTimeFrameToListPriceByTimeFrameDTO = function(){
			var timeFrame = {};
			timeFrame.fromHour = 0;
			timeFrame.toHour = 24;
			timeFrame.price = vm.room.price;
			if(vm.listPriceByTimeFrameDTO == null){
				vm.listPriceByTimeFrameDTO = [];
			}
			vm.listPriceByTimeFrameDTO.push(timeFrame);
		}

		vm.deleteTimeFrame = function(index){
			vm.listPriceByTimeFrameDTO.splice(index, 1);
		}

		vm.back = function(){
			$state.go('room');
		}

		function checkValidListTimeFrame(){
			var validCounter = 0;
			for(var i=0;i<vm.listPriceByTimeFrameDTO.length;i++){
				var el = vm.listPriceByTimeFrameDTO[i];
				if(Number(el.fromHour) > -1 && Number(el.toHour) > -1 && Number(el.toHour) <= 24){
					if(Number(el.price) > -1){
						validCounter +=1;
					}
				}
			}
			console.log(Number(el.price));
			console.log(validCounter);
			console.log(vm.listPriceByTimeFrameDTO.length);
			if(validCounter == vm.listPriceByTimeFrameDTO.length){
				return true;
			}
			return false;
		}
		
	}
})();
