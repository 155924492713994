(function() {
    'use strict';

    angular
        .module('xposApp')
        .config(keyboardConfig);

    keyboardConfig.$inject = ['VKI_CONFIG'];

    function keyboardConfig(VKI_CONFIG) {
        // set below to true to make alerts look like toast
    	VKI_CONFIG.position = {
			of: 'body',
			my: 'center top',
			at: 'center bottom',
			at2: 'center bottom'
		};
		VKI_CONFIG.tabNavigation = true;
		VKI_CONFIG.css = {
			container: 'blue-grey darken-1',
			buttonDefault: 'btn waves-effect waves-light',
			buttonHover: 'orange',
			buttonAction: 'orange',
			buttonActive: 'orange',
			buttonDisabled: 'disabled'
		};
		VKI_CONFIG.extensions = {
			addTyping: false
		};
    }
})();
