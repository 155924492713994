(function() {
    'use strict';

    angular
        .module('xposApp')
        .factory('PasswordResetFinish', PasswordResetFinish);

    PasswordResetFinish.$inject = ['$resource', '$localStorage'];

    function PasswordResetFinish($resource, $localStorage) {
        var service = $resource($localStorage.API_URL+'api/account/reset-password/finish', {}, {});

        return service;
    }
})();
