(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderPosMergeTableController', OrderPosMergeTableController);

    OrderPosMergeTableController.$inject =  ['$timeout', '$rootScope', '$stateParams', '$uibModalInstance', 'AlertService','entity', 'OrderPos','TablePos','TableZone'];
   
    function OrderPosMergeTableController($timeout, $rootScope, $stateParams, $uibModalInstance, AlertService, entity, OrderPos,TablePos,TableZone) {
        var vm = this;
        vm.currentOrder = entity;
        vm.clear = clear;
        vm.mergeTable = mergeTable;
        vm.filter = filter;
        
        
        vm.filterTableZone = '';
      
        loadAllTable();
        loadAllTableZone();
      
        // ***
        // Load all tables resource
        function loadAllTable () {
            var dataQueryLoadAllTable = {
            	pageNo: null,
                pageSize: null,
                sortBy: sortLoadAllTable()
            };
            dataQueryLoadAllTable.requestStatusForOrder = true;
           
            TablePos.query(dataQueryLoadAllTable, onSuccessLoadAllTable, onErrorLoadAllTable);
            function sortLoadAllTable() {
            	var result = [];
                result.push('tableNo:asc');
                return result;
            }
            function onSuccessLoadAllTable(data, headers) {
                vm.tables = data.content;
                vm.allTables = shallowCopy(data.content);
            }
            function onErrorLoadAllTable(error) {
                AlertService.error(error.data.message);
            }
        }
        // ***
        // Load all table zones resource
        function loadAllTableZone () {
            var dataQueryLoadAllTableZone = {
            	pageNo: null,
                pageSize: null,
                sortBy: sortLoadAllTableZone()
            };
           
            TableZone.query(dataQueryLoadAllTableZone, onSuccessLoadAllTableZone, onErrorLoadAllTableZone);
            function sortLoadAllTableZone() {
            	var result = [];
                result.push('tableZoneName:asc');
                return result;
            }
            function onSuccessLoadAllTableZone(data, headers) {
                vm.tableZones = data.content;
                //console.log( vm.tableZones);
            }
            function onErrorLoadAllTableZone(error) {
                AlertService.error(error.data.message);
            }
        }
        
     // ***
        // Filter Functions
        function filter(filter, value){
            if(filter == 'TableZone'){
                if(value == 'All'){
                    vm.filterTableZone = '';
                    vm.tables = shallowCopy( vm.allTables);
                }
                if(value != 'All'){
                    vm.filterTableZone = value;
                    vm.tables = [];
                    for(var i = 0;i< vm.allTables.length; i++){
                        if(vm.allTables[i].tableZone == vm.filterTableZone){
                            vm.tables.push(vm.allTables[i]);
                        }
                    }
                }
            }
            
        }
        
        
        // ***
        // Change table function
        function mergeTable(table){
            // update table
            var targetOrderId = table.currentOrderId ;
            var targetTable = null;
            for(var i =0; i< vm.allTables.length; i++){
                if(vm.allTables[i].tableNo == table.tableNo){
                    targetTable = vm.allTables[i];
                    targetTable.statusForOrder = "FINISH";
                    targetTable.currentOrderId = -1;
                }
               
            }
            if(targetTable != null){
                TablePos.update(targetTable,onSaveTargetTableSuccess, onSaveTargetError);
                function onSaveTargetTableSuccess(result){
                    // update order
                    vm.isSaving = true;
                    OrderPos.get({ id: targetOrderId  }, onSuccessLoadCurrentOrder, onErrorLoadCurrentOrder);
                    function onSuccessLoadCurrentOrder(data, headers) {
                        var targetOrder = data;
                        
                        for(var j = 0; j < targetOrder.listProducts.length; j++){
                            var targetProduct = targetOrder.listProducts[j];
                            var flag_check_exist = false;
                            for (var i = 0; i < vm.currentOrder.listProducts.length; i++) { 
                                if(vm.currentOrder.listProducts[i].product.productName == targetProduct.product.productName){
                                    changeQuantityOrderedProduct( i, targetProduct.quantity);
                                    flag_check_exist = true;
                                    break;
                                }
                            }
                            if(flag_check_exist == false){
                                vm.currentOrder.listProducts.push(targetProduct);
                                computeOrderedTotals();
                            }
                        }
                        
                        // delete target order
                        OrderPos.delete({ id: targetOrder.id  }, onDeleteSuccess);
                        function onDeleteSuccess(){
                            // update current order
                            OrderPos.update(vm.currentOrder, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                            function onSaveCurrentOrderSuccess (result) {
                                $rootScope.$broadcast('updateCurrentOrder', result);
                                $uibModalInstance.close(result);
                                vm.isSaving = false;
                            }
                            function onSaveCurrentOrderError () {
                                vm.isSaving = false;
                            }
                        }
                        
                    }
                    function onErrorLoadCurrentOrder(error) {
                        vm.isSaving = false;
                        AlertService.error(error.data.message);
                    }
                }
                function onSaveTargetError(error){
                    AlertService.error(error);
                }
            }

            
            
        	
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        // ****
        // private function
        function changeQuantityOrderedProduct(i, added){
        	vm.currentOrder.listProducts[i].quantity = vm.currentOrder.listProducts[i].quantity +added;
	    	vm.currentOrder.listProducts[i].totalTax = Math.round((vm.currentOrder.listProducts[i].quantity*vm.currentOrder.listProducts[i].tax) * 100) / 100;
	    	vm.currentOrder.listProducts[i].totalDiscount = Math.round((vm.currentOrder.listProducts[i].quantity*vm.currentOrder.listProducts[i].discount) * 100) / 100;
	    	vm.currentOrder.listProducts[i].totalMoney = Math.round((vm.currentOrder.listProducts[i].quantity*vm.currentOrder.listProducts[i].product.productPurchasePrice) * 100) / 100;
	    	
	    	computeOrderedTotals();
        }
        // private function
        function computeOrderedTotals(){
        	vm.currentOrder.subTotal = 0;
        	vm.currentOrder.totalDiscount = 0;
        	vm.currentOrder.totalVAT = 0;
        	for (var i = 0; i < vm.currentOrder.listProducts.length; i++) { 
        		vm.currentOrder.subTotal += vm.currentOrder.listProducts[i].totalMoney;
        		vm.currentOrder.totalDiscount += vm.currentOrder.listProducts[i].totalDiscount;
        		vm.currentOrder.totalVAT += vm.currentOrder.listProducts[i].totalTax;
    		}
        	vm.currentOrder.totalValue = vm.currentOrder.subTotal - vm.currentOrder.totalDiscount + vm.currentOrder.totalVAT;
        	vm.currentOrder.subTotal = Math.round((vm.currentOrder.subTotal) * 100) / 100;
        	vm.currentOrder.totalDiscount = Math.round((vm.currentOrder.totalDiscount) * 100) / 100;
        	vm.currentOrder.totalVAT = Math.round((vm.currentOrder.totalVAT) * 100) / 100;
        	
        }

         function shallowCopy( original )  
        {
            if(original == null || original == 'undefined'){
                return null;
            }
            // First create an empty object with
            // same prototype of our original source
            var clone = Object.create( Object.getPrototypeOf( original ) ) ;

            var i , keys = Object.getOwnPropertyNames( original ) ;

            for ( i = 0 ; i < keys.length ; i ++ )
            {
                // copy each property into the clone
                Object.defineProperty( clone , keys[ i ] ,
                    Object.getOwnPropertyDescriptor( original , keys[ i ] )
                ) ;
            }

            return clone ;
        }
        
    }
})();
