(function() {
	'use strict';

	angular.module('xposApp').controller('ProductDialogController',
			ProductDialogController);

	ProductDialogController.$inject = ['Config','Principal', '$timeout', '$scope', '$stateParams','$state','entity', 'Product', 'Upload', '$localStorage'];

	function ProductDialogController(Config, Principal, $timeout, $scope, $stateParams,$state,entity, Product, Upload, $localStorage) {
		var vm = this;
		vm.product = entity;
		//console.log(vm.product);
		vm.apiUrl = $localStorage.API_URL;
		vm.save = save;
		$timeout(function() {
			angular.element('.form-group:eq(0)>input').focus();
		});


		getAccount();
        function getAccount(callback) {
            Principal.identity().then(function (account) {
                vm.account = account;
                // $rootScope.flag = vm.account.langKey;
                vm.isAuthenticated = Principal.isAuthenticated;
                 if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
                 }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
                 }

                 //console.log(vm.account);
                 if(callback != null){
                 	callback();
                 }
                 getGroup();
            });
        }

		function save() {
			_save();

			function _save(){
				vm.isSaving = true;
				if (vm.product.id !== null) {
					//console.log(vm.product);
					Product.update(vm.product, onSaveSuccess, onSaveError);
				} else {
					vm.product.store = vm.account.store;
					Product.save(vm.product, onSaveSuccess, onSaveError);
				}
			}
			
			function onSaveSuccess(result) {
				$state.go('product');
				vm.isSaving = false;
			}

			function onSaveError(result) {
				
				if(result.status == 400){
					alert("Không được phép tạo phòng mới trùng tên với phòng cũ");
				}
				vm.isSaving = false;
			}
		}

		
		function getGroup(){
				var dataQuery = {
                   storeId : vm.account.store.id,
                   type: 'Nhóm sản phẩm'
                };
               
                // get Products
                Config.query(dataQuery, onSuccess, onError);
                function sort() {
                    var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                    return result;
                }
                function onSuccess(data, headers) {
                    vm.groups = data.content;
                    console.log(vm.groups);
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
		}

		vm.back = function(){
			$state.go('product');
		}

		
	}
})();
