(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('CustomerDialogController', CustomerDialogController);

    CustomerDialogController.$inject = ['$q','$http','$localStorage','JhiLanguageService','Auth','$timeout', '$state','$scope', '$stateParams', 'entity', 'Customer'];

    function CustomerDialogController ($q, $http, $localStorage, JhiLanguageService,  Auth, $timeout,$state, $scope, $stateParams,  entity, Customer) {
        var vm = this;
        vm.customer = entity;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        var makeRestCall   = function (url) {
            return $http.get($localStorage.API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

        

        function save () {
            vm.isSaving = true;
            if (vm.customer.id !== null) {
                Customer.update(vm.customer, onSaveSuccess, onSaveError);
            } else {
                Customer.save(vm.customer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $state.go('customer');
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.back = function(){
            $state.go('customer');
        }

       
       
       

    }

})();
