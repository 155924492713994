(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', '$timeout', 'Auth', 'RegisterAppSrevice','$rootScope', '$localStorage','PosSetting', '$state', '$http', '$q','$location','$window','$scope','SyncDataService'];

    function RegisterController ($translate, $timeout, Auth, RegisterAppSrevice, $rootScope, $localStorage, PosSetting, $state, $http, $q, $location, $window, $scope, SyncDataService) {
        var vm = this;
                    
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.register = register;
        vm.registerApp = {};
        vm.checkSync = checkSync;
        vm.login = login;
        vm.showDeleteData = false;
        vm.successSync = false;
        vm.status = {
            tableZone: false,
            table: false,
            product: false,
            productGroup: true,
            productCategory: true,
            user :false,
            setting : false,
            deleteData: false
        };
        
        /*
        vm.registerApp = {
            customerCode:"AE65DF94364222996B2A5321B6A7C6FE",
            productCode:"88DDC5EECF13CD3FC376270D67EF9C4A",
            fullName:"Test2",
            email:"test2",
            localservice:"localhost:9090",
            license:"GAWQE-F9A4J-CGZ2K-SDFJW-HWXRW-LM5WV-SSU2L-JM2DG-A9KEC-CYPQU-QTTT8-NSA3R-68F9B-4EH28-ZENLA-A"
        }
        */
        vm.success = null;
        $rootScope.$broadcast('isHideNav');
        var makeRestCall   = function (url) {
            return $http.get(url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };
        function handleRegister(){
            console.log("vm.registerApp:");
            console.log(vm.registerApp);
            RegisterAppSrevice.register(vm.registerApp).then(function (result) {
                if (result.code === 1){
                    vm.success = 'OK';
                    vm.successSync = 'OK';
                    vm.error = false;
                    $localStorage.validateKey = result.validate;
                    $localStorage.customerCode = vm.registerApp.customerCode;
                    $localStorage.productCode = vm.registerApp.productCode;
                    $localStorage.fullName = vm.registerApp.fullName;
                    $localStorage.email = vm.registerApp.email;
                    $localStorage.API_URL_VAL = vm.registerApp.localservice;
                    $localStorage.API_URL = "http://"+vm.registerApp.localservice+"/";
                    console.log(vm.registerApp.localservice+"-"+$localStorage.API_URL_VAL);
                   
          
                    makeRestCall($localStorage.API_URL+'api/setting?type=CUSTOMERCODE').then(function(data){
                        var saveSetting = data.data;
                        saveSetting.type = "CUSTOMERCODE";
                        vm.success = "OK";
                        /*
                        if (saveSetting.setting!=vm.registerApp.customerCode){
                            vm.showDeleteData = true;
                            deleteAll();
                        } else {
                            vm.status.deleteData =true;
                        }
                        */
                        saveSetting.setting = vm.registerApp.customerCode;
                        PosSetting.update(saveSetting).$promise.then(function(response) {

                           sync();
                            
                        }, function(response) {
                            console.log("Update error");
                        });;
                        // $state.go('home',{reload:true});
                   
                        
                        
                    },function(error){
                        // var saveSetting = {};
                        // saveSetting.type = "CUSTOMERCODE";
                        // saveSetting.setting = vm.registerApp.customerCode;
                        // PosSetting.save(saveSetting);
                    });

                } else {
                    vm.error = 'ERROR'
                }
            }).catch(function (response) {
                console.log(response);
                vm.success = null;
                vm.error = 'ERROR'
            });
    }
    function login(){
        $localStorage.isFirstApp = true;
        $state.go('home');
    }
    function register () {
        try {
            /*
            //get mac address
            var macaddress = require('node-macaddress');
            macaddress.one(function (err, mac) {
                vm.registerApp.hardwareId = mac;
                handleRegister();

            });
            */
            // get bios serial
            var serialNumber = require('serial-number');
            serialNumber(function (err, value) {
                vm.registerApp.hardwareId = value;
                console.log("vm.registerApp.hardwareId: "+vm.registerApp.hardwareId);
                handleRegister();
            });

        } catch(e){
            // if you not electron
            vm.registerApp.hardwareId = "MACTEST";
            console.log("vm.registerApp.hardwareId: "+vm.registerApp.hardwareId);
            handleRegister();
        }
    }
    function checkSync(){
            if (vm.status.user == true)
            {
                return true;
            }
            return false;
            /*
            if (vm.status.tableZone == true && vm.status.table == true 
                && vm.status.product == true && vm.status.productGroup == true && vm.status.deleteData)
            {
                return true;
            }
            return false;
            */
        }

        function sync(){
            //deleteAll();
            //syncTableZone();
            //syncTable();
            syncUser();
            //syncSetting();
            //syncProduct();
        }
        function deleteAll(){
            SyncDataService.deleteAll().then(function(data){
                vm.status.deleteData = true;
            },function(error){
                vm.status.deleteData = true;
            })
        }
        function syncTableZone(){
            SyncDataService.syncTableZone().then(function(data){
                vm.status.tableZone = true;
            },function(error){
                vm.status.tableZone = true;
            })
        }

        function syncTable(){
            SyncDataService.syncTable().then(function(data){
                vm.status.table = true;
            },function(error){
                vm.status.table = true;
            })
        }

        function syncUser(){
            SyncDataService.syncUser().then(function(data){
                vm.status.user = true;
            },function(error){
                vm.status.user = true;
            })
        }

        function syncSetting(){
            SyncDataService.syncSetting().then(function(data){
                vm.status.setting = true;
            },function(error){
                vm.status.setting = true;
            })
        }

        function syncProduct(){
            SyncDataService.deleteProduct().then(function(data){
                syncCategory();
                // syncProductGroup();
            },function(error){
                
            })
        }
        function syncCategory(){
            SyncDataService.syncCategory().then(function(data){
                syncProductGroup();
            },function(error){
                
            })
        }

        function syncProductGroup(){
            SyncDataService.syncProductGroup().then(function(data){
                syncProductData();
            },function(error){
                
            })
        }

        function syncProductData(){
            SyncDataService.syncProduct().then(function(data){
                 vm.status.product = true;
            },function(error){
                
            })
        }
    }
})();
