(function() {
	'use strict';

	angular.module('xposApp').controller('StockinDialogController',
			StockinDialogController);

	StockinDialogController.$inject = ['Principal', 'Product','$timeout', '$scope', '$stateParams','$state','entity', 'Stockin', 'Upload', '$localStorage'];

	function StockinDialogController(Principal, Product, $timeout, $scope, $stateParams,$state,entity, Stockin, Upload, $localStorage) {
		var vm = this;
		vm.stockin = {};
		vm.stockin.inputAt = new Date();

		vm.apiUrl = $localStorage.API_URL;
		vm.save = save;

		Principal.identity().then(function(account) {
            vm.currentAccount = account;
			vm.stockin.waiter = vm.currentAccount.login;
			loadAllProduct();
        });

		
		function loadAllProduct() {
        		var dataQuery = {
                };
               dataQuery.storeId = vm.currentAccount.store.id;
                // get Products
                Product.query(dataQuery, onSuccessGetProduct, onErrorGetProduct);
                function sort() {
                    var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                    return result;
                }
                function onSuccessGetProduct(data, headers) {
                   
                    vm.products = data.content;
                    for(var i=0;i<vm.products.length;i++){
                    	vm.products[i].quantity = 0;
                    }
                    
                }
                function onErrorGetProduct(error) {
                    AlertService.error(error.data.message);
                }
        }

		function save() {
			vm.isSaving = true;
			if (vm.stockin.id != null) {
				Stockin.update(vm.stockin, onSaveSuccess, onSaveError);
			} else {
				var listStockinProduct = [];
				for(var i=0;i< vm.products.length;i++){
					if(vm.products[i].quantity >0){
						var stockinProductDTO = {};
						stockinProductDTO.productId = vm.products[i].id;
						stockinProductDTO.productName = vm.products[i].productName;
						stockinProductDTO.productCode = vm.products[i].productCode;
						stockinProductDTO.inputQuantity = vm.products[i].quantity;
						listStockinProduct.push(stockinProductDTO);
					}
					
				}
				vm.stockin.listStockinProduct = listStockinProduct;
				Stockin.save(vm.stockin, onSaveSuccess, onSaveError);
			}
			
		}

		function onSaveSuccess(result) {
			$state.go('stockin');
			vm.isSaving = false;
		}

		function onSaveError(result) {
			
			if(result.status == 400){
				alert("The code is invalid!");
			}
			vm.isSaving = false;
		}


		vm.back = function(){
			$state.go('stockin');
		}

		
	}
})();
