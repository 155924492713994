
(function() {
    'use strict';

    angular
        .module('xposApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('stockin', {
            parent: 'entity',
            url: '/stockin?page&sort&waiter&shipper',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.stockin.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stockin/stockin.html',
                    controller: 'StockinController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        waiter: $stateParams.waiter,
                        shipper: $stateParams.shipper
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('stockin-detail', {
            parent: 'stockin',
            url: '/stockin/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.stockin.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stockin/stockin-detail.html',
                    controller: 'StockinDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Stockin', function($stateParams, Stockin) {
                    return Stockin.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'stockin',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('stockin-detail.edit', {
            parent: 'stockin-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stockin/stockin-dialog.html',
                    controller: 'StockinDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Stockin','$stateParams', function(Stockin,$stateParams) {
                            return Stockin.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('stockin.new', {
            parent: 'stockin',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stockin/stockin-dialog.html',
                    controller: 'StockinDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function () {
                            return {
                                stockinCode: null,
                                stockinArticalNumber: null,
                                stockinName: null,
                                stockinImage: null,
                                stockinPurchasePrice: null,
                                stockinSalePrice: null,
                                stockinCurrencyUnit: null,
                                stockinType: null,
                                lastUpdate: null,
                                version: null,
                                id: null
                            };
                }
            }
            
        })
        .state('stockin.edit', {
            parent: 'stockin',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stockin/stockin-dialog.html',
                    controller: 'StockinDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Stockin','$stateParams', function(Stockin,$stateParams) {
                            return Stockin.get({id : $stateParams.id}).$promise;
                }]
            }
            
        })
        .state('stockin.delete', {
            parent: 'stockin',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stockin/stockin-delete-dialog.html',
                    controller: 'StockinDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Stockin', function(Stockin) {
                            return Stockin.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('stockin', null, { reload: 'stockin' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('stockin.edit-quantity-popup', {
            parent: 'stockin',
            url: '/{id}/edit-quantity',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stockin/stockin-edit-quantity.html',
                    controller: 'EditQuantityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Stockin', function(Stockin) {
                            return Stockin.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('stockin', null, { reload: 'stockin' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
