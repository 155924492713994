(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('StockinController', StockinController);

    StockinController.$inject = ['Auth','Principal', '$q','Upload','$timeout','$rootScope','$stateParams','$state', 'Stockin', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$localStorage','$http'];

    function StockinController(Auth, Principal, $q,Upload, $timeout, $rootScope, $stateParams, $state, Stockin, ParseLinks, AlertService, paginationConstants, pagingParams, $localStorage, $http) {

        var vm = this;
        vm.$state = $state;
        vm.apiUrl = $localStorage.API_URL;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 15;
        vm.cleanTransition = cleanTransition;
        vm.search = {};
        
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        loadAll();
        var makeRestCall   = function (url) {
            return $http.get($localStorage.API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

        

        function loadAll () {
        	var dataQuery = {
                   
                };
               
                // get Stockins
                Stockin.query(dataQuery, onSuccessGetStockin, onErrorGetStockin);
                function sort() {
                    var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                    return result;
                }
                function onSuccessGetStockin(data, headers) {
                    vm.totalItems =data.totalElements;
                    vm.queryCount = data.totalElements;
                    vm.stockins = data.content;
                    vm.page = pagingParams.page;
                }
                function onErrorGetStockin(error) {
                    AlertService.error(error.data.message);
                }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var query = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                stockinCode: vm.search.stockinCode,
                stockinArticalNumber: vm.search.stockinArticalNumber,
                stockinName: vm.search.stockinName,
                stockinType: vm.search.stockinType == 'ALL' ? '' : vm.search.stockinType,
                stockinCategoryId: vm.search.stockinCategoryId == 'ALL' ? '' : vm.search.stockinCategoryId,
                stockinGroupId: vm.search.stockinGroupId == 'ALL' ? '' : vm.search.stockinGroupId
            }
            
            $state.transitionTo($state.$current, query);
        }
        
        function cleanTransition(){
        	vm.search.stockinCode = '';
        	vm.search.stockinArticalNumber = '';
        	vm.search.stockinName = '';
        	vm.search.stockinType = 'ALL';
        	vm.search.stockinCategoryId = 'ALL';
        	vm.search.stockinGroupId = 'ALL';
        	transition();
        }

       

      

        vm.selectStockin = function(stockin){
            vm.currentStockinId = stockin.id;
        }

        vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }

    }
})();
