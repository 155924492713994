(function() {
	'use strict';

	angular.module('xposApp').controller('OrderPosMakeNewOrderController',
			OrderPosMakeNewOrderController);

	OrderPosMakeNewOrderController.$inject = [ '$timeout', '$rootScope',
			'$stateParams', '$uibModalInstance', 'AlertService', 'OrderPos', 'Principal' ];

	function OrderPosMakeNewOrderController($timeout, $rootScope, $stateParams,
			$uibModalInstance, AlertService, OrderPos, Principal) {
		var vm = this;
		vm.tableNo = $stateParams.tableNo;
		vm.clear = clear;
		vm.createNewOrder = createNewOrder;
		vm.currentOrder = null;
		vm.showErrorOrderAfterReport = false;
		function createNewOrder() {
			vm.currentOrder = {};
			vm.currentOrder.id = null;
			vm.currentOrder.tablePos = vm.tableNo;
			vm.currentOrder.customerDTO = {};
			vm.currentOrder.customerDTO.customerName = "";
			vm.currentOrder.splitOrder = "NO";
			vm.currentOrder.numberGuest = 1;
			vm.currentOrder.orderStatus = "INPROGRESS";
			
			Principal.identity().then(function(account) {
				vm.currentOrder.waiter = account.login;
			});
			vm.currentOrder.startedAt = new Date();
			vm.currentOrder.totalValue = 0;

			createNewtOrderToDB();
		}

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		// private function
		function createNewtOrderToDB() {
			//console.log("Request Create New Order:")
			//console.log(JSON.stringify(vm.currentOrder, null, "    ") )
			OrderPos.save(vm.currentOrder, onCreateNewtOrderSuccess,
					onCreateNewtOrderSuccess);

			function onCreateNewtOrderSuccess(result) {
				if(result.status == 400){
					vm.showErrorOrderAfterReport = true;
				}else{
					vm.currentOrder = result;
					$rootScope.$broadcast('newOrder', vm.currentOrder);
					 $uibModalInstance.dismiss('cancel');
				}

				
			}

			function onSaveCurrentOrderError(error) {
				
				AlertService.error(error.data);
			}
		}

	}
})();
