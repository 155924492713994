(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('ErrorRegisterController', ErrorRegisterController);


    ErrorRegisterController.$inject = ['$translate', '$timeout', 'Auth', 'RegisterAppSrevice','$rootScope', '$localStorage','PosSetting', '$state'];

    function ErrorRegisterController ($translate, $timeout, Auth, RegisterAppSrevice, $rootScope, $localStorage, PosSetting, $state) {
        var vm = this;
        $rootScope.$broadcast('isHideNav');
        vm.registerApp = {
            customerCode : $localStorage.customerCode,
            productCode:   $localStorage.productCode,
            fullName:      $localStorage.fullName,
            email:         $localStorage.email,
            license:       $localStorage.validateKey
        }
        vm.extend = function (){
            $localStorage.extend = true;
            delete $localStorage.validateKey;
            delete $localStorage.email;
            delete $localStorage.fullName;
            delete $localStorage.productCode;
            delete $localStorage.customerCode;
            delete $localStorage.API_URL;
            delete $localStorage.API_URL_VAL;
            $state.go('register');
        }
    }
})();
