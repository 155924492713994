(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('ThuchiDeleteController',ThuchiDeleteController);

    ThuchiDeleteController.$inject = ['$localStorage','$uibModalInstance', 'entity', 'Thuchi'];

    function ThuchiDeleteController($localStorage,  $uibModalInstance, entity, Thuchi) {
        var vm = this;
        vm.apiUrl = $localStorage.API_URL;
        vm.thuchi = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        
        function confirmDelete (id) {
           Thuchi.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
            
        }
    }
})();
