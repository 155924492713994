(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderPosSelectMealController', OrderPosSelectMealController);

    OrderPosSelectMealController.$inject = ['$state','$stateParams', 'OrderPos', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function OrderPosSelectMealController($state, $stateParams, OrderPos, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;
       
        vm.test = $stateParams.table;

        
        
    }
})();
