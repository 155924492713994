(function() {
    'use strict';

    angular
        .module('xposApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('order-pos', {
            parent: 'entity',
            url: '/order-pos?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.orderPos.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-pos/order-pos.html',
                    controller: 'OrderPosController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('order-pos-detail', {
            parent: 'order-pos',
            url: '/order-pos/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.orderPos.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-pos/order-pos-detail.html',
                    controller: 'OrderPosDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'OrderPos', function($stateParams, OrderPos) {
                    return OrderPos.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'order-pos',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('order-pos-detail.edit', {
            parent: 'order-pos-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/order-pos-dialog.html',
                    controller: 'OrderPosDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('order-pos.new', {
            parent: 'order-pos',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/order-pos-dialog.html',
                    controller: 'OrderPosDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                tablePos: null,
                                waiter: null,
                                startedAt: null,
                                endAt: null,
                                orderStatus: null,
                                products: null,
                                totalValue: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('order-pos', null, { reload: 'order-pos' });
                }, function() {
                    $state.go('order-pos');
                });
            }]
        })

        //*************
        .state('new-order', {
            parent: 'order-pos',
            url: '/table-map?page&sort&search&tableNo&tableZone',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.orderPos.createNew.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-pos/order-pos-create-new.html',
                    controller: 'OrderPosCreateNewController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        tableNo: $stateParams.tableNo,
                        tableZone: $stateParams.tableZone
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

      .state('select-meal', {
            parent: 'order-pos',
            url: '/select-meal?table',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.orderPos.createNew.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-pos/order-pos-select-meal.html',
                    controller: 'OrderPosSelectMealController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('make-order.split-bill', {
            parent: 'make-order',
            url: '/split/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Split Order'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-pos/make-order-split.html',
                    controller: 'OrderPosSplitController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'OrderPos', function($stateParams, OrderPos) {
                    return OrderPos.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'make-order',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('make-order.payment', {
            parent: 'make-order',
            url: '/{id}/payment',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/payment-dialog.html',
                    controller: 'PaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('make-order', {
            parent: 'order-pos',
            url: '/make-order?customerId',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'xposApp.orderPos.createNew.actionMakeOrder'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-pos/make-order.html',
                    controller: 'OrderPosMakeOrderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('order-pos.create-new-order', {
            parent: 'make-order',
            url: '/new?tableNo',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-new-order.html',
                    controller: 'OrderPosMakeNewOrderController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('order-pos.delete-order', {
            parent: 'make-order',
            url: '/delete?order',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-delete-order.html',
                    controller: 'OrderPosDeleteOrderController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
       .state('order-pos.change-table', {
            parent: 'make-order',
            url: '/{id}/changetable',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-change-table.html',
                    controller: 'OrderPosChangeTableController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('order-pos.merge-table', {
            parent: 'make-order',
            url: '/{id}/mergetable',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-merge-table.html',
                    controller: 'OrderPosMergeTableController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('order-pos.quick-comment', {
            parent: 'make-order',
            url: '/{id}/{productName}/comment',
            data: {
                authorities: ['ROLE_USER']
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-quick-comment.html',
                    controller: 'OrderPosQuickCommentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('order-pos.select-customer', {
            parent: 'make-order',
            url: '/{id}/selectcustomer',
            data: {
                authorities: ['ROLE_USER']
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-select-customer.html',
                    controller: 'OrderPosSelectCustomerController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('order-pos.add-customer', {
            parent: 'make-order',
            url: '/{id}/addcustomer',
            data: {
                authorities: ['ROLE_USER']
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-add-customer.html',
                    controller: 'OrderPosAddCustomerController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('order-pos.split-bil', {
            parent: 'make-order',
            url: '/{id}/split-bil',
            data: {
                authorities: ['ROLE_USER']
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/make-order-split-bil.html',
                    controller: 'OrderPosSplitBilController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('make-order', null, { reload: 'make-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        //**************
        .state('order-pos.edit', {
            parent: 'order-pos',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/order-pos-dialog.html',
                    controller: 'OrderPosDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('order-pos', null, { reload: 'order-pos' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('order-pos.delete', {
            parent: 'order-pos',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-pos/order-pos-delete-dialog.html',
                    controller: 'OrderPosDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OrderPos', function(OrderPos) {
                            return OrderPos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('order-pos', null, { reload: 'order-pos' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
