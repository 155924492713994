(function () {
    'use strict';

    angular
        .module('xposApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['PosSetting','$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$rootScope', '$localStorage', '$scope', '$translate','ngVirtualKeyboardService','JhiLanguageService', '$sessionStorage','$http', '$q','OrderPos'];

    function NavbarController(PosSetting, $state, Auth, Principal, ProfileService, LoginService, $rootScope, $localStorage, $scope, $translate, ngVirtualKeyboardService, JhiLanguageService, $sessionStorage,$http, $q, OrderPos) {
        var vm = this;
        vm.isNav = true;
        vm.isExternalDisplay = false;
        vm.isNavbarCollapsed = true;
        vm.isSearchButton = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.styleDefault = true;
        vm.shutdownMode = {};



         $rootScope.$on('isHideNav', function() {
            vm.isNav = false;
        });

        $rootScope.$on('isExternalDisplay', function() {
            vm.isNav = false;
            vm.isExternalDisplay = true;
        });

        $rootScope.$on('order-fast-on', function() {
            vm.isNav = false;
        });
        $rootScope.$on('order-fast-off', function() {
            vm.isNav = true;
        });

         $rootScope.$on('turnOffSmart', function() {
            vm.shutdownMode.setting = '1';
        });

         var makeRestCall   = function (url) {

            return $http.get($localStorage.API_URL+url)
                .then(function (response) {
                  return response;
                }, function (response) {
                    // something went wrong
                    return $q.reject(response.data);
                });
        };

       makeRestCall('api/setting?type=STYLE').then(function(data){
                     if(data.data == null || data.data.setting == 'Default'){
                        vm.styleDefault = true;
                    }else {
                         vm.styleDefault = false;
                    }
                   },function(error){

                });

        $rootScope.$on('resetShutdownMode', function() {
           makeRestCall('api/setting?type=SHUTDOWNMODE').then(function(data){
                vm.shutdownMode = data.data;
                vm.shutdownMode.setting = '0';

                },function(error){
            });

            //console.log( vm.shutdownMode);
        });

         $rootScope.$on("shudownModeAfterSync", function(evt,data){
           vm.shutdownMode.setting = data;
        });

        makeRestCall('api/setting?type=SHUTDOWNMODE').then(function(data){
                vm.shutdownMode = data.data;
                },function(error){
            });
         makeRestCall('api/setting?type=USE_TIM').then(function(data){
                      vm.useTIM = data.data.setting;
                  },function(error){
          });
         makeRestCall('api/setting?type=SHOW_BILLS_SUPER_USER').then(function(data){
                      vm.showBillsSuper = data.data.setting;
                  },function(error){
          });
        makeRestCall('api/setting?type=POS_VERSION').then(function(data){
                      vm.posVersion = data.data.setting;
                  },function(error){
          });


        var cleanHttpErrorListener = $rootScope.$on('innovationApp.httpError', function (event, httpResponse) {
            switch (httpResponse.status) {
            // connection refused, server not reachable
            case 0:
                break;
            case 400:
                break;
            case 404:
                $state.go('pagenotfound');
                break;
            default:

            }
        });

        vm.goOrder = function(){
           $state.go('order-fast-advance');
        }

        vm.shutDown = function(){
          if(vm.shutdownMode.setting == '0'){
                vm.shutdownMode.setting = '1';
                PosSetting.update(vm.shutdownMode,function(result){
                    $rootScope.$broadcast('turnOffSmart');
                })
                //
          }else
          if(vm.shutdownMode.setting == '1'){
                //if(vm.currentRole == 'ROLE_ADMIN'){
                  // check there is opening order
                  checkOpeningOrder(function(){
                    var shutdown = require('electron-shutdown-command');
                    shutdown.shutdown();
                    console.log("Shut down!");
                  });

                /*}else{
                  var notify = document.getElementById("alertShutdown").innerHTML;
                  alert(notify);
                }*/

          }
        }
        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        initKeyboard();

        $scope.listYear = [];
        $scope.search = search;

        $rootScope.$on("searchData", function(evt,data){
        	vm.searchData = data;
          if(vm.searchData.length >0){
            vm.isSearchButton = false;
          }else{
            vm.isSearchButton = true;
          }
         });

        getAccount();

        $scope.$on('languageChange', function(data) {
            initKeyboard();
        });

        function initKeyboard(){
            $scope.t01Config = {layout: JhiLanguageService.getKeyboard(), kt: 'US International', showKbSelect: true,
             numberPad: true, showVersion: true, usePreview: false, autoAccept : true, reposition : true,
             position : {
                  // null (attach to input/textarea) or a jQuery object (attach elsewhere)
                  of : $(window),
                  my : 'center bottom',
                  at : 'center bottom',
                  // at2 is used when "usePreview" is false (centers keyboard at the bottom
                  // of the input/textarea)
                  at2: 'center bottom',
                  collision: 'flipfit flipfit'
                }
        };
        }
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                // $rootScope.flag = vm.account.langKey;
                vm.isAuthenticated = Principal.isAuthenticated;
                 vm.currentRole = 'ROLE_USER';
                  for (var j = 0; j < vm.account.authorities.length; j++) {
                    if(vm.account.authorities[j] == 'ROLE_ADMIN'){
                        vm.currentRole = 'ROLE_ADMIN';
                        break;
                    }else if(vm.account.authorities[j] == 'ROLE_SUPER_USER'){
                      vm.currentRole = 'ROLE_SUPER_USER';
                      break;
                    }
                  }
            });
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function search() {
          if(vm.searchData.length >0){
            vm.isSearchButton = false;
          }else{
            vm.isSearchButton = true;
          }
            if(vm.searchData.includes("0000000000000000")){
               vm.searchData = '';
               //$localStorage.searchData = null;
                Auth.logout();
                $state.go('home');
            }else{
              if($localStorage.searchData  != "clear"){
                $rootScope.$emit('searchData',vm.searchData);
                vm.searchData = null;
              }
              $localStorage.searchData = vm.searchData;

            }

        }

      vm.clearSearch = clearSearch;
      function clearSearch() {
        vm.searchData = '';
          if(vm.searchData.length >0){
            vm.isSearchButton = false;
          }else{
            vm.isSearchButton = true;
          }
            //$localStorage.searchData = vm.searchData;
            $rootScope.$emit('searchData',vm.searchData);
        }
        $rootScope.$on('authenticationSuccess', function () {
            getAccount();
            vm.isNav = true;
        });



        vm.checkLogOut = checkLogOut;
         function checkLogOut( inputCheck){
            if(inputCheck != null && inputCheck.includes("0000000000000000")){
              vm.inputFocus = '';
                Auth.logout();
                $state.go('home');
            }

        }

        vm.reFocus =reFocus;
        function reFocus(){
            angular.element('#input_focus').focus();
        }

        function checkOpeningOrder (callback) {
           var dataQueryLoadAllOrder = {
                pageNo: null,
                pageSize: null,
                sortBy: sort()
            };

            dataQueryLoadAllOrder.orderStatus = "INPROGRESS";

            OrderPos.query(dataQueryLoadAllOrder, onSuccess, onError);
            function sort() {
                var result = [];
                result.push('id:asc');
                return result;
            }
            function onSuccess(data, headers) {
                var orders = data.content;

                if(orders.length == 0){
                    vm.ordersIsOpen = false;
                    callback();
                }else{
                    vm.ordersIsOpen = true;
                    alert("There are any opeing order")
                }
            }
            function onError(error) {
                AlertService.error(error.data);
            }
        }

        vm.onKeyup = function(event){
            //console.log(event.keyCode);
           
            if(event.keyCode == 13){
                search();
            }
            //console.log(vm.searchedProductIndex);
        }

        vm.inputSearch = function(){
          if(vm.searchData.length == 0){
            search();
          }
        }

        

    }
})();
