(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderMatxaController', OrderMatxaController);

    OrderMatxaController.$inject = ['$uibModal','Auth','$timeout','$state','$rootScope','$stateParams', 'Principal',
    'Ticket', 'ParseLinks', 'AlertService',
     'paginationConstants', 'pagingParams', 'PosSetting', '$http', '$q', '$scope','$localStorage', 
      'BillMatxa'];

    function OrderMatxaController( $uibModal,Auth, $timeout, $state, $rootScope, $stateParams,Principal,
         Ticket, ParseLinks, AlertService, paginationConstants, 
        pagingParams, PosSetting, $http, $q, $scope, $localStorage,   BillMatxa 
        ) {

        var vm = this;
        vm.apiUrl = $localStorage.API_URL;
        var API_URL = $localStorage.API_URL;
        vm.selectedOrderedProductIndex = null;

        function makePutCall(url,data) {
            return $http.put(vm.apiUrl+url,data)
                .then(function (response) {
                  return response;
                }, function (response) {
                    return $q.reject(response.data);
                });
        };

       Principal.identity().then(function(account) {
            vm.currentAccount = account;
            loadAllTickets();
            loadAllBillMatxa();
        });

      
       function loadAllTickets () {
                var dataQuery = {
                    storeId: vm.currentAccount.store.id
                };
               
                // get Products
                Ticket.query(dataQuery, onSuccess, onError);
               
                function onSuccess(data, headers) {
                    vm.tickets = data.content;
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
        }


        function loadAllBillMatxa () {
                var dataQuery = {
                    storeId: vm.currentAccount.store.id,
                    paymentStatus: 'CHUA_IN_VE',
                    status: 'THANH_CONG'
                };
               
                // get Products
                BillMatxa.query(dataQuery, onSuccess, onError);
               
                function onSuccess(data, headers) {
                    vm.billMatxas = data.content;
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
        }
        vm.selectTicket = function(ticket){
          var txt =  "Bạn thực sự muốn bán vé này?";
          var r = confirm(txt);
          if (r == true) {
             var billMatxa = {};
             billMatxa.id = null;
             billMatxa.store = vm.currentAccount.store;
             billMatxa.ticket = ticket;
             billMatxa.waiter = vm.currentAccount.login;
             billMatxa.startedAt = new Date();
             billMatxa.paymentStatus = 'CHUA_IN_VE';

             BillMatxa.save(billMatxa, onSaveSuccess, onSaveError);
             function onSaveSuccess(){
              loadAllBillMatxa();
             }
             function onSaveError(){

             }

           }
        }

       vm.cancelTicket = function(billMatxa){
          billMatxa.status = "XOA";
          billMatxa.waiter = vm.currentAccount.login;
          billMatxa.endAt = new Date();
          billMatxa.totalValue = billMatxa.ticket.price - billMatxa.discountValue;
          BillMatxa.update(billMatxa, onSaveSuccess, onSaveError);
             function onSaveSuccess(){
              loadAllBillMatxa();
             }
             function onSaveError(){

             }
       }

       vm.printBillMatxa = function(billMatxa){
        console.log(billMatxa);
          billMatxa.paymentStatus = "DA_IN_VE";
          billMatxa.waiter = vm.currentAccount.login;
          billMatxa.endAt = new Date();
          billMatxa.totalValue = billMatxa.ticket.price - billMatxa.discountValue;
          
          
          BillMatxa.update(billMatxa, onSaveSuccess, onSaveError);
             function onSaveSuccess(){
                $state.go('billMatxa-print-preview',{id:billMatxa.id});
             }
             function onSaveError(){

             }
           
       }

      
        vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }

        // copy function
        function shallowCopy( original )
        {
            if(original == null || original == 'undefined'){
                return null;
            }
            // First create an empty object with
            // same prototype of our original source
            var clone = Object.create( Object.getPrototypeOf( original ) ) ;

            var i , keys = Object.getOwnPropertyNames( original ) ;

            for ( i = 0 ; i < keys.length ; i ++ )
            {
                // copy each property into the clone
                Object.defineProperty( clone , keys[ i ] ,
                    Object.getOwnPropertyDescriptor( original , keys[ i ] )
                ) ;
            }

            return clone ;
        }

    }
})();
