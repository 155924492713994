(function() {
    'use strict';

    angular
        .module('xposApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES', 'storageService'];

    function translationStorageProvider($cookies, $log, LANGUAGES, storageService ) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            // if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
            if (LANGUAGES.indexOf(storageService.get(name)) === -1) {
                // $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to preferred language "en"');
                //$cookies.putObject(name, 'de');
                storageService.put(name,'en');
            }
            // return $cookies.getObject(name);
            return storageService.get(name);
        }

        function put(name, value) {
            // $cookies.putObject(name, value);
            storageService.put(name,value);
        }
    }
})();
