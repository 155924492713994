(function() {
    'use strict';

    angular
        .module('xposApp')
        .factory('PasswordResetInit', PasswordResetInit);

    PasswordResetInit.$inject = ['$resource', '$localStorage'];

    function PasswordResetInit($resource, $localStorage) {
        var service = $resource($localStorage.API_URL+'api/account/reset-password/init', {}, {});

        return service;
    }
})();
