(function() {
	'use strict';

	angular.module('xposApp').controller('OrderPosAddCustomerController',
			OrderPosAddCustomerController);

	OrderPosAddCustomerController.$inject = [ '$timeout', '$scope',
			'$stateParams', '$uibModalInstance', 'AlertService', 'entity','Customer' ,'JhiLanguageService','Location'];

		function OrderPosAddCustomerController($timeout, $scope,
					$stateParams, $uibModalInstance, AlertService, entity, Customer, JhiLanguageService, Location) {
						var vm = this;

						vm.customer = entity;
						if(vm.customer.customerType == '' || vm.customer.customerType == null){
							vm.customer.customerType = 'ECONOMIC';
						}
						vm.clear = clear;
						vm.datePickerOpenStatus = {};
						vm.openCalendar = openCalendar;
						vm.save = save;
						vm.streets = [];
						initKeyboard();


				$scope.$on('languageChange', function(data) {
		            initKeyboard();
		        });


		        function initKeyboard(){
		            
		            vm.t05Config = {layout: JhiLanguageService.getKeyboard(), kt: 'US International', showKbSelect: true,
		             numberPad: true, showVersion: true, usePreview: false, autoAccept : true, reposition : true,

		             position : {
                          // null (attach to input/textarea) or a jQuery object (attach elsewhere)
                          of : '#keyboard-wrapper',
                          my : 'center top',
                          at : 'center bottom',
                          // at2 is used when "usePreview" is false (centers keyboard at the bottom
                          // of the input/textarea)
                          at2: 'center bottom',
                          collision: 'flipfit flipfit'
                        },
                        beforeVisible: function(e, keyboard, el) {
                          var inModal = $(el).parents('.modal').length > 0;
                          if (inModal) {
                            keyboard.$keyboard.appendTo($(el).parents('.modal-body'));
                          }
                        }
		         }
		        }


				$timeout(function (){
						angular.element('.form-group:eq(0)>input').focus();
				});

				function clear () {
						$uibModalInstance.dismiss('cancel');
				}

				function save () {
						vm.isSaving = true;
						vm.customer.lastUpdateAt = new Date();
						vm.customer.street = document.getElementById("streetInput").value;
						if (vm.customer.id !== null) {
								Customer.update(vm.customer, onSaveSuccess, onSaveError);
						} else {
								Customer.save(vm.customer, onSaveSuccess, onSaveError);
						}
				}

				function onSaveSuccess (result) {
						$scope.$emit('xposApp:customerUpdate', result);
						$uibModalInstance.close(result);
						vm.isSaving = false;
				}

				function onSaveError () {
						vm.isSaving = false;
				}

				vm.datePickerOpenStatus.lastUpdateAt = false;

				function openCalendar (date) {
						vm.datePickerOpenStatus[date] = true;
				}

				//*
        // region autocomplete street by postcode
        //*
        /*initiate the autocomplete function on the "streetInput" element, and pass along the streets array as possible autocomplete values:*/
        vm.activeStreetSuggestion = activeStreetSuggestion
        
        function activeStreetSuggestion(){
            if(vm.customer.customerPostCode != null && vm.customer.customerPostCode.length >0){
                loadAllLocations(vm.customer.customerPostCode);

            }
        }
        

        function loadAllLocations(postCode){
            var dataQueryLoadAllLocations = {
                pageNo : null,
                pageSize : null,
                postCode : postCode,
                sortBy : sortLoadAllLocations()
            };
            

            Location.query(dataQueryLoadAllLocations, onSuccessLoadAllLocation,
                    onErrorLoadAllLocations);
            function sortLoadAllLocations() {
                var result = [];
                result.push('street:asc');
                return result;
            }
            function onSuccessLoadAllLocation(data, headers) {
                vm.locations = data.content;
                if(vm.locations  != null){
                    vm.streets = vm.locations.map(
                        function getStreet(location) {
                            return location.street;
                        }

                    );
                    // console.log(vm.streets);
                    /*An array containing all the street names of the postcode:*/
                    autocomplete(document.getElementById("streetInput"), vm.streets);
                }
                
               
            }
            function onErrorLoadAllLocations(error) {
                AlertService.error(error);
            }
        }

	}
})();
