(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('ThuchiController', ThuchiController);

    ThuchiController.$inject = ['Store','$scope','Auth','Principal','$q','Upload','$timeout','$rootScope','$stateParams','$state', 'Thuchi', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$localStorage','$http'];

    function ThuchiController(Store, $scope, Auth, Principal, $q,Upload, $timeout, $rootScope, $stateParams, $state, Thuchi, ParseLinks, AlertService, paginationConstants, pagingParams, $localStorage, $http) {

        var vm = this;
        vm.$state = $state;
        vm.apiUrl = $localStorage.API_URL;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 100;
        vm.cleanTransition = cleanTransition;
        vm.search = {};
       
        vm.dateformat = 'dd-MM-yyyy';
        vm.dateSearch = new Date();
        vm.dateSearch.setHours(0);
        vm.dateSearch.setMinutes(0);
        vm.dateSearch.setSeconds(0);

        vm.dateTo = new Date();
         vm.dateTo.setHours(0);
        vm.dateTo.setMinutes(0);
        vm.dateTo.setSeconds(0);

        $scope.open1 = function() {
            $scope.popup1.opened = true;
          };

        $scope.popup1 = {
            opened: false
          };

           $scope.open2 = function() {
            $scope.popup2.opened = true;
          };

        $scope.popup2 = {
            opened: false
          };

        Principal.identity().then(function(account) {
            vm.account = account;
            if(vm.account.authorities.includes("ROLE_SUPER_ADMIN")){
                    vm.currentRole = 'ROLE_SUPER_ADMIN';
            }else if(vm.account.authorities.includes("ROLE_ADMIN")){
                    vm.currentRole = 'ROLE_ADMIN';
             }else if(vm.account.authorities.includes("ROLE_USER")){
                    vm.currentRole = 'ROLE_USER';
             }

            vm.filterStore = vm.account.store;
            loadAllStore();
            loadAll();
           
        });


        vm.loadAll = loadAll;
        function loadAll () {
             var searchFrom = new Date(vm.dateSearch);
            var searchTo = new Date(vm.dateTo);
        	var dataQuery = {
                   searchFrom: searchFrom,
                    searchTo: searchTo
                };
                if(vm.filterStore.id != 1){
                    dataQuery.storeId =  vm.filterStore.id;
                 }
               //console.log(dataQuery);
                // get Thuchis
                Thuchi.query(dataQuery, onSuccessGetThuchi, onErrorGetThuchi);
                function sort() {
                    var result = [vm.predicate + ':' + (vm.reverse ? 'asc' : 'desc')];
                    return result;
                }
                function onSuccessGetThuchi(data, headers) {
                    vm.thuchis = data.content;
                    vm.totalThu =0;
                    vm.totalChi = 0;
                    for(var i = 0;i<vm.thuchis.length;i++){
                        if(vm.thuchis[i].thu != null){
                            vm.totalThu += vm.thuchis[i].thu;
                        }
                        if(vm.thuchis[i].chi != null){
                            vm.totalChi += vm.thuchis[i].chi;
                        }
                    }
                }
                function onErrorGetThuchi(error) {
                    AlertService.error(error.data.message);
                }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var query = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }
            
            $state.transitionTo($state.$current, query);
        }
        
        function cleanTransition(){

        	transition();
        }


        vm.selectThuchi = function(thuchi){
            vm.currentThuchiId = thuchi.id;
        }

        vm.loadAllStore = loadAllStore;
         function loadAllStore(){
                 var dataQuery = {
                 };
                 // get Bills
                 Store.query(dataQuery, onSuccess, onError);
                 
                 function onSuccess(data, headers) {
                     vm.stores = data.content;
                    //console.log(vm.stores);
                 }
                 function onError(error) {
                     AlertService.error(error);
                 }
         }

        vm.logout = function() {
            Auth.logout();
            $state.go('home');
        }

    }
})();
