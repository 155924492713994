(function() {
    'use strict';

    angular
        .module('xposApp')
        .controller('OrderPosChangeTableController', OrderPosChangeTableController);

    OrderPosChangeTableController.$inject =  ['$timeout', '$rootScope', '$stateParams', '$uibModalInstance','AlertService', 'entity', 'OrderPos','TablePos','TableZone'];
   
    function OrderPosChangeTableController($timeout, $rootScope, $stateParams, $uibModalInstance, AlertService, entity, OrderPos,TablePos,TableZone) {
        var vm = this;
        vm.currentOrder = entity;
        vm.clear = clear;
        vm.changeTable = changeTable;
        vm.filter = filter;
        
        
        vm.filterTableZone = '';
      
        loadAllTable();
        loadAllTableZone();
      
        // ***
        // Load all tables resource
        function loadAllTable () {
            var dataQueryLoadAllTable = {
            	pageNo: null,
                pageSize: null,
                sortBy: sortLoadAllTable()
            };
            dataQueryLoadAllTable.requestStatusForOrder = true;
           
            TablePos.query(dataQueryLoadAllTable, onSuccessLoadAllTable, onErrorLoadAllTable);
            function sortLoadAllTable() {
            	var result = [];
                result.push('tableNo:asc');
                return result;
            }
            function onSuccessLoadAllTable(data, headers) {
                vm.tables = data.content;
                vm.allTables = shallowCopy(data.content);
                //console.log(vm.tables);
            }
            function onErrorLoadAllTable(error) {
                AlertService.error(error.data.message);
            }
        }
        // ***
        // Load all table zones resource
        function loadAllTableZone () {
            var dataQueryLoadAllTableZone = {
            	pageNo: null,
                pageSize: null,
                sortBy: sortLoadAllTableZone()
            };
           
            TableZone.query(dataQueryLoadAllTableZone, onSuccessLoadAllTableZone, onErrorLoadAllTableZone);
            function sortLoadAllTableZone() {
            	var result = [];
                result.push('tableZoneName:asc');
                return result;
            }
            function onSuccessLoadAllTableZone(data, headers) {
                vm.tableZones = data.content;
                //console.log( vm.tableZones);
            }
            function onErrorLoadAllTableZone(error) {
                AlertService.error(error.data.message);
            }
        }
        
        // ***
        // Filter Functions
        function filter(filter, value){
        	if(filter == 'TableZone'){
        		if(value == 'All'){
            		vm.filterTableZone = '';
            		vm.tables = shallowCopy( vm.allTables);
            	}
            	if(value != 'All'){
            		vm.filterTableZone = value;
        	    	vm.tables = [];
                    for(var i = 0;i< vm.allTables.length; i++){
                        if(vm.allTables[i].tableZone == vm.filterTableZone){
                            vm.tables.push(vm.allTables[i]);
                        }
                    }
                }
        	}
        	
        }
        
        
        // ***
        // Change table function
        function changeTable(tableNo){
        	vm.isSaving = true;
            var oldTable = null;
            var newTable = null;
            for(var i =0; i< vm.allTables.length; i++){
                if(vm.allTables[i].tableNo == tableNo){
                    newTable = vm.allTables[i];
                    newTable.statusForOrder = "INPROGRESS";
                    newTable.currentOrderId = vm.currentOrder.id;
                }
                if(vm.allTables[i].tableNo == vm.currentOrder.tablePos){
                    oldTable = vm.allTables[i];
                    oldTable.statusForOrder = "FINISH";
                    oldTable.currentOrderId = -1;
                }
            }
            if(newTable != null && oldTable != null){
                TablePos.update(oldTable,onSaveOldTableSuccess, onSaveOldTableError);
                function onSaveOldTableSuccess(result){
                    TablePos.update(newTable,onSaveNewTableSuccess, onSaveNewTableError);
                    function onSaveNewTableSuccess(result){
                        vm.currentOrder.tablePos = tableNo;
                        OrderPos.update(vm.currentOrder, onSaveCurrentOrderSuccess, onSaveCurrentOrderError);
                        function onSaveCurrentOrderSuccess (result) {
                            $rootScope.$broadcast('updateCurrentOrder', result);
                            $uibModalInstance.close(result);
                            vm.isSaving = false;
                        }

                        function onSaveCurrentOrderError () {
                            vm.isSaving = false;
                        }
                    }
                    function onSaveNewTableError(error){
                        AlertService.error(error);
                    }
                }
                function onSaveOldTableError(error){
                    AlertService.error(error);
                }
                
            }
        	
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        

         function shallowCopy( original )  
        {
            if(original == null || original == 'undefined'){
                return null;
            }
            // First create an empty object with
            // same prototype of our original source
            var clone = Object.create( Object.getPrototypeOf( original ) ) ;

            var i , keys = Object.getOwnPropertyNames( original ) ;

            for ( i = 0 ; i < keys.length ; i ++ )
            {
                // copy each property into the clone
                Object.defineProperty( clone , keys[ i ] ,
                    Object.getOwnPropertyDescriptor( original , keys[ i ] )
                ) ;
            }

            return clone ;
        }
    }
})();
